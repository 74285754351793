import { ExpenseTrackerDbTables } from './../common/interface';
import { Injectable } from '@angular/core';
import Dexie from 'dexie';
import { TrackerPermissions } from 'src/app/common/app-interfaces';

export type Table<T> = T & { id?: number | string };

@Injectable({
  providedIn: 'root',
})
export class IndexedDBService extends Dexie {
  private dbversion = 2;
  userId: string = '';
  trackerPermissions!: TrackerPermissions[];

  private tableVersions: { [key: string]: number } = {
    avatars: 1,
    budget_detail: 2,
    group_tags: 1,
    groups: 1,
    icon_uploads: 1,
    income: 1,
    reoccurring_tracks: 1,
    tracker: 1,
    tracker_permission: 1,
    tracker_type: 1,
    ignore_tags: 1,
    user_group: 1,
    users: 1,
  };

  constructor() {
    super('tracker-indexed-db', { autoOpen: true });

    console.log('creating db');
    this.checkAndSetupTables();
  }

  private async checkAndSetupTables() {
    for (const table in this.tableVersions) {
      await this.setupTable(table);
    }

    for (const table in this.tableVersions) {
      const storedVersion = localStorage.getItem(`${table}_version`);
      if (this.tableVersions[table].toString() !== storedVersion) {
        await this.clearTable(table);
        localStorage.setItem(
          `${table}_version`,
          this.tableVersions[table].toString(),
        );
      }
    }
  }

  private async clearTable(tableName: string): Promise<void> {
    try {
      const table = this.table(tableName);
      if (table) {
        await table.clear();
        console.log(`Table "${tableName}" cleared`);
      }
    } catch (error) {
      console.error(`Error clearing table "${tableName}":`, error);
    }
  }

  private async setupTable(tableName: string) {
    try {
      switch (tableName) {
        case 'avatars':
          await this.ensureTableExists('avatars', {
            avatars: '++id,uuid,name,avatarUrl,extraInfo',
          });
          break;
        case 'budget_detail':
          await this.ensureTableExists('budget_detail', {
            budget_detail:
              '++id,userId,budgetId,tag,groupTagId,budgetType,amount,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted',
          });
          break;
        case 'group_tags':
          await this.ensureTableExists('group_tags', {
            group_tags:
              '++id,uuid,userId,groupName,trackerTypeId,groupIcon,tags,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,deletedAt,versionNo',
          });
          break;
        case 'groups':
          await this.ensureTableExists('groups', {
            groups:
              '++id,uuid,userId,trackerTypeId,name,icon,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,versionNo',
          });
          break;
        case 'icon_uploads':
          await this.ensureTableExists('icon_uploads', {
            icon_uploads:
              '++id,uuid,userId,tag,icon,iconURL,extraInfo,trackerTypeId,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,versionNo',
          });
          break;
        case 'income':
          await this.ensureTableExists('income', {
            income:
              '++id,uuid,userId,userGroupId,amount,groupId,iInterval,iDay,tags,tagsArray,isReoccurring,dated,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
          });
          break;
        case 'reoccurring_tracks':
          await this.ensureTableExists('reoccurring_tracks', {
            reoccurring_tracks:
              '++id,uuid,userId,trackerTypeId,trackerValue,tags,reoccurringInterval,reoccurringDay,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedAt,deletedBy,versionNo',
          });
          break;
        case 'tracker':
          await this.ensureTableExists('tracker', {
            tracker:
              '++id,uuid,userId,userGroupId,trackerTypeId,trackerValue,dated,groupId,isReoccurring,reoccurringInterval,reoccurringDay,tags,extraInfo,day,month,year,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
          });
          break;
        case 'tracker_permission':
          await this.ensureTableExists('tracker_permission', {
            tracker_permission:
              '++id,trackerTypeId,userId,sharedWithUserId,sharedWithEmail,canAdd,canUpdate,canDelete,canInvite,isActive,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,versionNo',
          });
          break;
        case 'tracker_type':
          await this.ensureTableExists('tracker_type', {
            tracker_type:
              '++id,uuid,trackerName,trackerIcon,extraInfo,createdAt,createdBy,updatedAt,updatedBy,isDeleted,deletedBy,deletedAt,versionNo',
          });
          break;
        case 'ignore_tags':
          await this.ensureTableExists('ignore_tags', {
            ignore_tags:
              '++id,userId,tag,monthly,quarterly,semiannual,yearly,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedBy,versionNo',
          });
          break;
        case 'user_group':
          await this.ensureTableExists('user_group', {
            user_group:
              '++id,uuid,userId,groupName,icon,tag,extraInfo,createdBy,createdAt,updatedBy,updatedAt,isDeleted,deletedAt,versionNo',
          });
          break;
        case 'users':
          await this.ensureTableExists('users', {
            users:
              '++id,uuid,username,password,dated,createdAt,updatedAt,isDeleted,deletedBy,lastLogin,extraInfo,versionNo',
          });
          break;
        default:
          console.error(`Unknown table: ${tableName}`);
      }
    } catch (error) {
      console.error(`Error setting up table "${tableName}":`, error);
    }
  }

  async ensureTableExists(
    tableName: ExpenseTrackerDbTables,
    schema: { [tableName: string]: string | null },
    indexes?: string, // optional parameter for indexes
  ): Promise<void> {
    try {
      const table = this._allTables[tableName];
      if (!table) {
        let schemaString = schema[tableName];
        if (indexes) {
          schemaString += indexes;
        }
        //@ts-ignore
        let newSchema: Record<ExpenseTrackerDbTables, string | null> = {};
        newSchema[tableName] = schemaString;
        this.version(this.dbversion).stores(newSchema);
      }
    } catch (error) {
      console.error(`Error ensuring table "${tableName}":`, error);
      throw error;
    }
  }

  async upsertBulk<T>(tableName: ExpenseTrackerDbTables, rows: Table<T>[]) {
    try {
      const table = this.table(tableName);
      await table.bulkPut(rows);
      return rows;
    } catch (error) {
      console.error('error in upsertBulk', error);
    }
    return rows;
  }

  async getAllFromDbForTableByUser<T>(
    tableName: ExpenseTrackerDbTables,
    userIds: number[] = [],
  ): Promise<T[]> {
    const table = this.table(tableName);
    if (!table) return [];

    // Add current user id to the list of user ids, if empty array at least add current user id
    if (userIds.indexOf(+this.userId) === -1) {
      userIds.push(+this.userId);
    }
    console.log('since tracker has been shared userIds:', userIds);

    const allResults: T[] = [];

    for (const userId of userIds) {
      const results = await this.table(tableName)
        .where('userId')
        .equals(userId)
        .toArray()
        .then((j) => {
          console.log('since tracker retrieved data for user:', userId);
          return j.map((i: any) => {
            if (i.extraInfo && typeof i.extraInfo === 'string') {
              i.extraInfo = JSON.parse(i.extraInfo);
            }
            if (i.tagsArray && typeof i.tagsArray === 'string') {
              i.tagsArray = JSON.parse(i.tagsArray as unknown as string);
            }
            return i;
          });
        });
      allResults.push(...results);
    }

    return allResults;
  }

  async getMaxId(tableName: ExpenseTrackerDbTables): Promise<number> {
    // Take user ids from tracker permissions
    // Since most of the tables are for tracker type 1
    // Other than income table
    const userIds = this.trackerPermissions.map((i) => i.userId) || [];
    userIds.push(+this.userId);

    // Fetch records and sort them in JavaScript
    try {
      const records = await this.table(tableName)
        .where('userId')
        .anyOf(userIds)
        .toArray();

      if (records.length === 0) return 0;

      // Sort records by id in descending order and get the last one (max id)
      records.sort((a, b) => b.id - a.id);
      console.log('max id:', records[0].id);
      return records[0].id;
    } catch (error) {
      console.log(error);
      return 0;
    }
  }

  async getAllRowsByFilters<T>(
    tableName: ExpenseTrackerDbTables,
    where: { [key: string]: any },
  ) {
    return (await this.table(tableName).where(where).toArray()) as T[];
  }

  async getRowById<T>(tableName: ExpenseTrackerDbTables, id: number) {
    return await this.table<T>(tableName).get(id);
  }
}
