@if (show) {
  <div
    role="alert"
    class="app-message"
    [ngClass]="{
      'message-error': type === MessageType.error,
      'message-warning': type === MessageType.warning,
      'message-success': type === MessageType.success,
      'message-announcement': type === MessageType.announcement,
    }"
  >
    <div class="message-wrapper">
      @if (type === MessageType.error) {
        <mat-icon class="message-icon">error_outline</mat-icon>
      }
      @if (type === MessageType.warning) {
        <mat-icon class="message-icon">warning</mat-icon>
      }
      @if (type === MessageType.success) {
        <mat-icon class="message-icon">notification_important</mat-icon>
      }
      @if (type === MessageType.announcement) {
        <mat-icon class="message-icon">notification_important</mat-icon>
      }
      @if (showMessageTitle && type === MessageType.error) {
        <span class="message-summary">Error</span>
      }
      @if (showMessageTitle && type === MessageType.warning) {
        <span class="message-summary">Warning</span>
      }
      @if (showMessageTitle && type === MessageType.success) {
        <span class="message-summary">Success</span>
      }
      @if (showMessageTitle && type === MessageType.announcement) {
        <span class="message-summary">Announcement</span>
      }
      <span class="message-detail">{{ message }}</span>
      <button
        type="button"
        mat-flat-button
        class="btn-close"
        type="button"
        (click)="show = false"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
}
