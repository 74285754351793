{
  "acupuncture": "acupuncture.png",
  "air freshener": "air freshener.png",
  "air purifier": "air purifier.png",
  "airport": "airport.png",
  "aliexpress": "aliexpress.png",
  "almond": "almond.png",
  "amazon music": "amazon music.png",
  "amazon shopping": "amazon shopping.png",
  "amazon": "amazon.png",
  "antiseptic cream": "antiseptic cream.png",
  "app store": "app store.png",
  "apple cloud storage": "apple cloud storage.png",
  "apple jam": "apple jam.png",
  "apple tv": "apple tv.png",
  "apple": "apple.png",
  "apples": "apples.png",
  "apron": "apron.png",
  "armchair": "armchair.png",
  "asparagus": "asparagus.png",
  "atiya": "atiya.png",
  "auto insurance": "auto insurance.png",
  "auto": "auto.png",
  "avacados": "avacados.png",
  "avocado": "avocado.png",
  "backpack": "backpack.png",
  "bacon strips": "bacon strips.png",
  "bacon": "bacon.png",
  "badminton": "badminton.png",
  "bag": "bag.png",
  "baguette": "baguette.png",
  "banana": "banana.png",
  "bandage": "bandage.png",
  "barber": "barber.png",
  "barbershop": "barbershop.png",
  "baseball": "baseball.png",
  "basketball": "basketball.png",
  "bathroom cabinet": "bathroom cabinet.png",
  "bathroom scale": "bathroom scale.png",
  "bathtub": "bathtub.png",
  "beard trimmer": "beard trimmer.png",
  "beauty": "beauty.png",
  "bed": "bed.png",
  "beef steak": "beef steak.png",
  "beef": "beef.png",
  "beer bottle": "beer bottle.png",
  "beer can": "beer can.png",
  "beer": "beer.png",
  "beet": "beet.png",
  "bell peppers": "bell peppers.png",
  "bell-peppers": "bell-peppers.png",
  "bento": "bento.png",
  "berry jam": "berry jam.png",
  "bhel-puri": "bhel-puri.png",
  "bikini": "bikini.png",
  "bill": "bill.png",
  "bills": "bills.png",
  "birthday-cake": "birthday-cake.png",
  "black forest": "black forest.png",
  "blanket": "blanket.png",
  "blender": "blender.png",
  "blinds": "blinds.png",
  "bmo": "bmo.png",
  "bobby pin": "bobby pin.png",
  "book shelf": "book shelf.png",
  "book": "book.png",
  "boxing": "boxing.png",
  "bra": "bra.png",
  "bread bun": "bread bun.png",
  "bread roll": "bread roll.png",
  "bread": "bread.png",
  "briefs": "briefs.png",
  "broccoli": "broccoli.png",
  "brocolli": "brocolli.png",
  "broom": "broom.png",
  "bubble tea": "bubble tea.png",
  "buffet": "buffet.png",
  "bun": "bun.png",
  "bunk bed": "bunk bed.png",
  "burger bun": "burger bun.png",
  "burger": "burger.png",
  "burrito": "burrito.png",
  "butter": "butter.png",
  "buying": "buying.png",
  "cabbage": "cabbage.png",
  "cake-rusk": "cake-rusk.png",
  "cake": "cake.png",
  "canada": "canada.png",
  "candy cane": "candy cane.png",
  "candy": "candy.png",
  "canned food": "canned food.png",
  "cantaloupes": "cantaloupes.png",
  "car fun": "car fun.png",
  "car pool": "car pool.png",
  "car service": "car service.png",
  "carpet": "carpet.png",
  "carrot": "carrot.png",
  "cash in rewards": "cash in rewards.png",
  "cash income": "cash income.png",
  "cash": "cash.png",
  "cashew": "cashew.png",
  "cauliflower": "cauliflower.png",
  "celery": "celery.png",
  "cereal": "cereal.png",
  "chair": "chair.png",
  "charcoal": "charcoal.png",
  "cheese sticks": "cheese sticks.png",
  "cheese string": "cheese string.png",
  "cheese": "cheese.png",
  "cherry": "cherry.png",
  "chicken-nuggets": "chicken-nuggets.png",
  "chicken": "chicken.png",
  "chiken drumsticks": "chiken drumsticks.png",
  "chikoo": "chikoo.png",
  "child care benefit": "child care benefit.png",
  "child care benefits": "child care benefits.png",
  "chili-pepper": "chili-pepper.png",
  "chipotle": "chipotle.png",
  "chips": "chips.png",
  "chocolate-bar": "chocolate-bar.png",
  "chocolate": "chocolate.png",
  "chopsticks": "chopsticks.png",
  "chunky chicken.jpg": "chunky chicken.jpg",
  "churros": "churros.png",
  "cibc": "cibc.png",
  "cinnamo stick": "cinnamo stick.png",
  "cinnamo sticks": "cinnamo sticks.png",
  "cinnamon rolls": "cinnamon rolls.png",
  "cinnamon-rolls": "cinnamon-rolls.png",
  "cleaver": "cleaver.png",
  "clementine": "clementine.png",
  "clementines": "clementines.png",
  "clinic": "clinic.png",
  "closet": "closet.png",
  "clothes": "clothes.png",
  "cocktail": "cocktail.png",
  "coconut water": "coconut water.png",
  "coffee beans": "coffee beans.png",
  "coffee grinder": "coffee grinder.png",
  "coffee pot": "coffee pot.png",
  "coffee table": "coffee table.png",
  "coffee to go": "coffee to go.png",
  "coffee": "coffee.png",
  "colander": "colander.png",
  "comb": "comb.png",
  "computer": "computer.png",
  "condensed milk": "condensed milk.png",
  "conditioner": "conditioner.png",
  "condom": "condom.png",
  "cookies": "cookies.png",
  "cooking oil": "cooking oil.png",
  "corn dog": "corn dog.png",
  "corn flour": "corn flour.png",
  "corn starch": "corn starch.png",
  "corn": "corn.png",
  "cosmetic brush": "cosmetic brush.png",
  "counselor": "counselor.png",
  "cpu": "cpu.png",
  "crab": "crab.png",
  "crepes": "crepes.png",
  "crib": "crib.png",
  "cricket": "cricket.png",
  "croissant": "croissant.png",
  "croutons": "croutons.png",
  "crunchyroll": "crunchyroll.png",
  "cucumber": "cucumber.png",
  "cupcake": "cupcake.png",
  "cupcakes": "cupcakes.png",
  "curry rice": "curry rice.png",
  "curtain": "curtain.png",
  "curtains": "curtains.png",
  "cutlery": "cutlery.png",
  "cutting board": "cutting board.png",
  "dairy free": "dairy free.png",
  "dairy": "dairy.png",
  "dasond": "dasond.png",
  "delivery-food": "delivery-food.png",
  "dental braces": "dental braces.png",
  "dental filling": "dental filling.png",
  "dental": "dental.png",
  "denture": "denture.png",
  "desk": "desk.png",
  "dimsum": "dimsum.png",
  "dinner": "dinner.png",
  "dishwasher": "dishwasher.png",
  "disney": "disney.png",
  "diy project": "diy project.png",
  "diy projects": "diy projects.png",
  "dollar": "dollar.png",
  "dollarama.jpg": "dollarama.jpg",
  "donut": "donut.png",
  "donuts": "donuts.png",
  "door": "door.png",
  "doordash": "doordash.png",
  "doughnut": "doughnut.png",
  "drawers": "drawers.png",
  "dressing": "dressing.png",
  "dumbbell": "dumbbell.png",
  "duster": "duster.png",
  "egg free": "egg free.png",
  "egg plant": "egg plant.png",
  "egg whites": "egg whites.png",
  "eggs": "eggs.png",
  "ei-benefits": "ei-benefits.png",
  "electric shaver": "electric shaver.png",
  "electric toothbrush": "electric toothbrush.png",
  "energy drink": "energy drink.png",
  "engine oil": "engine oil.png",
  "evaporated milk": "evaporated milk.png",
  "facial mask": "facial mask.png",
  "family": "family.png",
  "fan": "fan.png",
  "fido": "fido.png",
  "filleted fish": "filleted fish.png",
  "filter": "filter.png",
  "fire extinguisher": "fire extinguisher.png",
  "fish filleted": "fish filleted.png",
  "fish": "fish.png",
  "flip flops": "flip flops.png",
  "flour": "flour.png",
  "foil sheet": "foil sheet.png",
  "food-basic.jpg": "food-basic.jpg",
  "food": "food.png",
  "football": "football.png",
  "foundation makeup": "foundation makeup.png",
  "french fries": "french fries.png",
  "fried rice": "fried rice.png",
  "fries": "fries.png",
  "fruit": "fruit.png",
  "fruits": "fruits.png",
  "frying pan": "frying pan.png",
  "funimation": "funimation.png",
  "funimations": "funimations.png",
  "furniture": "furniture.png",
  "fusilli": "fusilli.png",
  "garage": "garage.png",
  "garlic": "garlic.png",
  "gas burner": "gas burner.png",
  "gas-station": "gas-station.png",
  "gas": "gas.png",
  "gift": "gift.png",
  "ginger": "ginger.png",
  "glasses case": "glasses case.png",
  "gluten free": "gluten free.png",
  "golf": "golf.png",
  "google drive": "google drive.png",
  "google play": "google play.png",
  "google": "google.png",
  "grapes": "grapes.png",
  "graphics card": "graphics card.png",
  "grater": "grater.png",
  "gravy boat": "gravy boat.png",
  "green chilli": "green chilli.png",
  "green onion": "green onion.png",
  "green pepper": "green pepper.png",
  "green tea": "green tea.png",
  "grill pan": "grill pan.png",
  "grill": "grill.png",
  "groceries": "groceries.png",
  "grocery bag": "grocery bag.png",
  "grocery bags": "grocery bags.png",
  "ground chicken": "ground chicken.png",
  "hair clip": "hair clip.png",
  "hair cutting": "hair cutting.png",
  "hair dryer": "hair dryer.png",
  "haleem": "haleem.png",
  "hamburger": "hamburger.png",
  "hammer": "hammer.png",
  "hbo go": "hbo go.png",
  "headband": "headband.png",
  "hockey": "hockey.png",
  "home expense": "home expense.png",
  "home insurance": "home insurance.png",
  "home internet": "home internet.png",
  "home office": "home office.png",
  "home": "home.png",
  "honey": "honey.png",
  "hospital": "hospital.png",
  "hot dog": "hot dog.png",
  "hotdog": "hotdog.png",
  "house keeping": "house keeping.png",
  "household": "household.png",
  "hst": "hst.png",
  "hulu": "hulu.png",
  "humidifier": "humidifier.png",
  "ice cream": "ice cream.png",
  "ice": "ice.png",
  "iceberg lettuce": "iceberg lettuce.png",
  "icons8-hair-dryer-48": "icons8-hair-dryer-48.png",
  "ikea": "ikea.png",
  "imac": "imac.png",
  "index.html": "index.html",
  "infuse water": "infuse water.png",
  "inhaler": "inhaler.png",
  "insulin pen": "insulin pen.png",
  "internet": "internet.png",
  "iqbal-foods": "iqbal-foods.png",
  "iron": "iron.png",
  "itunes": "itunes.png",
  "jalapeno": "jalapeno.png",
  "jalebi": "jalebi.png",
  "jam": "jam.png",
  "jeans": "jeans.png",
  "jelly": "jelly.png",
  "jk": "jk.png",
  "juice": "juice.png",
  "kale": "kale.png",
  "kebab": "kebab.png",
  "ketchup": "ketchup.png",
  "keto": "keto.png",
  "kettle": "kettle.png",
  "keyboard": "keyboard.png",
  "kitchen hammer": "kitchen hammer.png",
  "kitchen lamp": "kitchen lamp.png",
  "kitchen scale": "kitchen scale.png",
  "kitchen tools": "kitchen tools.png",
  "kitchen utensils": "kitchen utensils.png",
  "kitchen-towel": "kitchen-towel.png",
  "kitchen": "kitchen.png",
  "kitchenware": "kitchenware.png",
  "kiwi": "kiwi.png",
  "knife": "knife.png",
  "ksp": "ksp.svg",
  "lacrosse": "lacrosse.png",
  "ladle": "ladle.png",
  "lamb": "lamb.png",
  "lamp": "lamp.png",
  "laptop": "laptop.png",
  "lasagna": "lasagna.png",
  "laundry": "laundry.png",
  "lemon tea": "lemon tea.png",
  "lemon": "lemon.png",
  "lemonade": "lemonade.png",
  "lettuce": "lettuce.png",
  "light bulb": "light bulb.png",
  "light-bulb": "light-bulb.png",
  "lightning": "lightning.png",
  "lights": "lights.png",
  "lip gloss": "lip gloss.png",
  "lipstick": "lipstick.png",
  "lobster": "lobster.png",
  "logo_TheBodyShop": "logo_TheBodyShop.png",
  "lollipop": "lollipop.png",
  "loonie": "loonie.png",
  "lotto": "lotto.png",
  "low carb": "low carb.png",
  "macaroni": "macaroni.png",
  "macaroon": "macaroon.png",
  "makeup": "makeup.png",
  "margarita": "margarita.png",
  "marshmallow": "marshmallow.png",
  "masala chai": "masala chai.png",
  "mascara": "mascara.png",
  "mayonnaise": "mayonnaise.png",
  "mcdonald": "mcdonald.png",
  "mcdonalds": "mcdonalds.png",
  "meat grinder": "meat grinder.png",
  "meat": "meat.png",
  "meatballs": "meatballs.png",
  "medical insurance": "medical insurance.png",
  "mediterranean": "mediterranean.png",
  "metro": "metro.png",
  "microsoft": "microsoft.png",
  "milk bottle": "milk bottle.png",
  "milk shake": "milk shake.png",
  "milk": "milk.png",
  "milkshake": "milkshake.png",
  "mineral water": "mineral water.png",
  "mirror": "mirror.png",
  "miscellaneous": "miscellaneous.png",
  "mitten": "mitten.png",
  "mittens": "mittens.png",
  "mixer": "mixer.png",
  "mobile-bill": "mobile-bill.png",
  "mochi": "mochi.png",
  "mojito": "mojito.png",
  "money": "money.png",
  "monitor": "monitor.png",
  "motherboard": "motherboard.png",
  "mouse": "mouse.png",
  "movie ticket": "movie ticket.png",
  "movie tickets": "movie tickets.png",
  "muffins": "muffins.png",
  "mushroom": "mushroom.png",
  "naan": "naan.png",
  "nachos": "nachos.png",
  "nail polish": "nail polish.png",
  "nas": "nas.png",
  "national masala": "national masala.png",
  "netflix": "netflix.png",
  "network card": "network card.png",
  "no sugar": "no sugar.png",
  "noodle": "noodle.png",
  "noodles": "noodles.png",
  "nuggets": "nuggets.png",
  "nut free": "nut free.png",
  "office chair": "office chair.png",
  "office": "office.png",
  "okra": "okra.png",
  "olive": "olive.png",
  "one drive": "one drive.png",
  "onigiri": "onigiri.png",
  "onion ring": "onion ring.png",
  "onion": "onion.png",
  "online training": "online training.png",
  "opener": "opener.png",
  "others": "others.png",
  "outing": "outing.png",
  "oyster": "oyster.png",
  "paella": "paella.png",
  "paleo": "paleo.png",
  "pan": "pan.png",
  "pancake": "pancake.png",
  "pancakes": "pancakes.png",
  "pant": "pant.png",
  "panties": "panties.png",
  "pants": "pants.png",
  "paper-plates": "paper-plates.png",
  "paratha": "paratha.png",
  "parking": "parking.png",
  "pastry spatula": "pastry spatula.png",
  "pc power cable": "pc power cable.png",
  "pc.jpg": "pc.jpg",
  "pc": "pc.svg",
  "peanut butter": "peanut butter.png",
  "peanuts": "peanuts.png",
  "pear": "pear.png",
  "pears": "pears.png",
  "peas": "peas.png",
  "pepper bell green": "pepper bell green.png",
  "petty cash": "petty cash.png",
  "phone bill": "phone bill.png",
  "pie": "pie.png",
  "pill": "pill.png",
  "pineapple": "pineapple.png",
  "pint": "pint.png",
  "pizza cutter": "pizza cutter.png",
  "pizza": "pizza.png",
  "plates": "plates.png",
  "plumbing": "plumbing.png",
  "plunger": "plunger.png",
  "pomegranate": "pomegranate.png",
  "pop corn": "pop corn.png",
  "pop corns": "pop corns.png",
  "popcorn": "popcorn.png",
  "porridge": "porridge.png",
  "pot": "pot.png",
  "potato": "potato.png",
  "potatoes": "potatoes.png",
  "potted plant": "potted plant.png",
  "pregnancy test": "pregnancy test.png",
  "present": "present.png",
  "pretzel": "pretzel.png",
  "property": "property.png",
  "protein jar": "protein jar.png",
  "pumpkin": "pumpkin.png",
  "quesadilla": "quesadilla.png",
  "radish": "radish.png",
  "ram": "ram.png",
  "raspberry pi": "raspberry pi.png",
  "raw": "raw.png",
  "razor": "razor.png",
  "README.md": "README.md",
  "real estate": "real estate.png",
  "red pepper": "red pepper.png",
  "refrigerator": "refrigerator.png",
  "rent": "rent.png",
  "restaurant": "restaurant.png",
  "reward": "reward.png",
  "rice bowl": "rice bowl.png",
  "rice cooker": "rice cooker.png",
  "rice": "rice.png",
  "roasted chicken": "roasted chicken.png",
  "rogers_logo": "rogers_logo.svg.png",
  "roku": "roku.png",
  "roll of paper": "roll of paper.png",
  "rolling pin": "rolling pin.png",
  "romaine lettuce": "romaine lettuce.png",
  "root beer": "root beer.png",
  "rugby": "rugby.png",
  "sake": "sake.png",
  "salad": "salad.png",
  "salami": "salami.png",
  "salmon": "salmon.png",
  "salt": "salt.png",
  "sandwich": "sandwich.png",
  "satay": "satay.png",
  "saucepan": "saucepan.png",
  "sausage": "sausage.png",
  "savings": "savings.png",
  "school": "school.png",
  "scotia": "scotia.png",
  "seafood": "seafood.png",
  "sephora-logo": "sephora-logo.png",
  "shake": "shake.png",
  "shampoo": "shampoo.png",
  "shan masala": "shan masala.png",
  "sheets": "sheets.png",
  "shoe-rack": "shoe-rack.png",
  "shop-costco": "shop-costco.png",
  "shoppers.jpg": "shoppers.jpg",
  "shopping": "shopping.png",
  "shorts": "shorts.png",
  "shovel": "shovel.png",
  "shower gel": "shower gel.png",
  "shrimp": "shrimp.png",
  "shrimps": "shrimps.png",
  "sink": "sink.png",
  "skin care": "skin care.png",
  "smoothies": "smoothies.png",
  "snack": "snack.png",
  "snacks": "snacks.png",
  "soap dispenser": "soap dispenser.png",
  "soap": "soap.png",
  "soccer": "soccer.png",
  "socks liner": "socks liner.png",
  "socks": "socks.png",
  "soda bottle": "soda bottle.png",
  "soda can": "soda can.png",
  "soda": "soda.png",
  "sofa": "sofa.png",
  "soft drink": "soft drink.png",
  "soju": "soju.png",
  "solar panel": "solar panel.png",
  "soup": "soup.png",
  "soy milk": "soy milk.png",
  "soy sauce": "soy sauce.png",
  "soy": "soy.png",
  "spaghetti": "spaghetti.png",
  "sparkling water": "sparkling water.png",
  "spatula": "spatula.png",
  "spice": "spice.png",
  "spices": "spices.png",
  "spinach": "spinach.png",
  "sponge": "sponge.png",
  "sports": "sports.png",
  "spotify": "spotify.png",
  "spray": "spray.png",
  "spring onion": "spring onion.png",
  "squid": "squid.png",
  "starbucks": "starbucks.png",
  "steak": "steak.png",
  "steamer": "steamer.png",
  "store": "store.png",
  "stove": "stove.png",
  "straight razor": "straight razor.png",
  "strawberry": "strawberry.png",
  "sub": "sub.png",
  "subscription": "subscription.png",
  "sugar": "sugar.png",
  "surfboard": "surfboard.png",
  "sushi": "sushi.png",
  "t-shirt": "t-shirt.png",
  "t-shirts": "t-shirts.png",
  "table tennis": "table tennis.png",
  "taco": "taco.png",
  "tacos": "tacos.png",
  "tax": "tax.png",
  "td": "td.png",
  "tea bags": "tea bags.png",
  "tea cup": "tea cup.png",
  "tea": "tea.png",
  "teapot": "teapot.png",
  "tempura": "tempura.png",
  "tenderizer": "tenderizer.png",
  "tennis": "tennis.png",
  "thai food": "thai food.png",
  "thai tea": "thai tea.png",
  "ticket": "ticket.png",
  "tickets": "tickets.png",
  "tims coffee": "tims coffee.png",
  "toast": "toast.png",
  "toaster": "toaster.png",
  "toilet paper": "toilet paper.png",
  "toilet-paper": "toilet-paper.png",
  "tomato": "tomato.png",
  "tomatoes": "tomatoes.png",
  "tongs": "tongs.png",
  "toothbrush": "toothbrush.png",
  "toothpaste": "toothpaste.png",
  "tortilla": "tortilla.png",
  "towel": "towel.png",
  "towels": "towels.png",
  "toy": "toy.png",
  "toys": "toys.png",
  "transportation": "transportation.png",
  "trip": "trip.png",
  "trousers": "trousers.png",
  "tshirt": "tshirt.png",
  "tshirts": "tshirts.png",
  "tteok": "tteok.png",
  "tube": "tube.png",
  "tupperware": "tupperware.png",
  "tweezers": "tweezers.png",
  "twitch": "twitch.png",
  "uber": "uber.png",
  "vacuum cleaner": "vacuum cleaner.png",
  "vegan": "vegan.png",
  "vegetable": "vegetable.png",
  "vegetables": "vegetables.png",
  "vegetarian": "vegetarian.png",
  "vehicle insurance": "vehicle insurance.png",
  "video card": "video card.png",
  "Virgin_Mobile": "Virgin_Mobile.svg.png",
  "vodka": "vodka.png",
  "waffle": "waffle.png",
  "waffles": "waffles.png",
  "wallet": "wallet.png",
  "walmart": "walmart.png",
  "walnut": "walnut.png",
  "wardrobe": "wardrobe.png",
  "warehouse": "warehouse.png",
  "washing machine": "washing machine.png",
  "waste": "waste.png",
  "water bottle": "water bottle.png",
  "water cooler": "water cooler.png",
  "watermelon": "watermelon.png",
  "waxing": "waxing.png",
  "web broker": "web broker.png",
  "weber": "weber.png",
  "weight scale": "weight scale.png",
  "wellsfargo": "wellsfargo.png",
  "whipping cream": "whipping cream.png",
  "whisk": "whisk.png",
  "windows store": "windows store.png",
  "wine opener": "wine opener.png",
  "wine": "wine.png",
  "wipes": "wipes.png",
  "wok box": "wok box.png",
  "workstation": "workstation.png",
  "x-ray": "x-ray.png",
  "yoghurt": "yoghurt.png",
  "yogurt": "yogurt.png",
  "youtube": "youtube.png",
  "zarda": "zarda.png",
  "sauce": "sauce.png",
  "alfredo sauce": "alfredo sauce.png",
  "pasta sauce": "pasta sauce.png",
  "masala dosa": "masala dosa.png",
  "entertainment": "entertainment.png",
  "chicken fried rice": "fried rice.png",
  "tip": "tip.png"
}
