import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Avatars } from '../app-interfaces';
import { environment } from '../../../environments/environment';
import { IconService } from '../../mini-apps/expense-app/services/icon.service';
import { ExpenseTrackerStoreState } from '../../mini-apps/expense-app/store/reducers';
import { SetUserInfo } from '../../store/settings/user/actions/user.actions';
import { UserService } from '../../store/settings/user/service/user.service';

@Component({
  selector: 'app-pick-avatar-modal',
  templateUrl: './pick-avatar-modal.component.html',
  styleUrls: ['./pick-avatar-modal.component.scss'],
})
export class PickAvatarModalComponent implements OnInit {
  avatars$: Observable<Avatars[]>;
  appIconUrl = environment.appIconUrl;
  currentAvatar!: string;
  returnAvatar: boolean = false;

  constructor(
    private store: Store<ExpenseTrackerStoreState>,
    private iconService: IconService,
    private userService: UserService,
    @Optional() public dialogRef: MatDialogRef<PickAvatarModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.currentAvatar = data.currentAvatar;
      this.returnAvatar = data.returnAvatar;
    }
    this.avatars$ = this.iconService.getAvatars();
  }

  updateAvatar(avatarUrl: string) {
    // if component doesn't want modal to update user avatar instead
    // component emits avatar by output() eventEmitter
    if (this.returnAvatar) {
      this.dialogRef.close(avatarUrl);
      return;
    }

    this.userService.UpdateUserAvatar(avatarUrl).subscribe((userInfo) => {
      this.currentAvatar = userInfo.extraInfo.avatar;
      this.store.dispatch(SetUserInfo({ user: userInfo }));
    });
  }
  ngOnInit(): void {}
}
