import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { IYearlyChart } from 'src/app/mini-apps/expense-app/components/yearly-report/yearly-report.component';
declare var ej: any;

@Directive({
  selector: '[appTagChart]',
})
export class TagChartDirective implements AfterViewInit {
  @Input() appTagChart!: IYearlyChart[];
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    var diagram = new ej.diagrams.Diagram({
      width: '2000px',
      height: '2000px',
      dataSourceSettings: {
        // set the unique field from data source
        id: 'id',
        // set the field which is used to identify the reporting person
        parentId: 'parentId',
        // define the employee data
        dataManager: this.appTagChart,
        doBinding: function (node: any, data: any) {
          // You will get the employee information in data argument and bind that value directly to node's built-in properties.
          node.annotations = [{ content: data.role }];
          node.style = { fill: data.color };
        },
      },
      layout: {
        // set the layout type
        type: 'OrganizationalChart',
      },
      // set the common settings for node and connector
      getNodeDefaults: this.nodeDefaults,
      getConnectorDefaults: this.connectorDefaults,
      // hide the gridlines in the diagram
      snapSettings: { constraints: ej.diagrams.SnapConstraints.None },
    });

    setTimeout(() => {
      diagram.appendTo('#yearlyExpenseChart');
    }, 1000);
  }

  nodeDefaults(node: any) {
    node.annotations[0].style.color = 'white';
    node.width = 120;
    return node;
  }

  connectorDefaults(connector: any) {
    connector.type = 'Orthogonal';
    connector.targetDecorator = { shape: 'None' };
    return connector;
  }
}
