import { createAction, props } from '@ngrx/store';
import {
  iGroup,
  iRecords,
} from 'src/app/mini-apps/expense-app/common/interface';

export enum GroupActionTypes {
  GROUP_LIST_SUCCESS = 'Group list SUCCESS',
  GROUP_LIST_GET = 'Group list GET',
  GROUP_ADD = 'Group list ADD',
  GROUP_ADD_SUCCESS = 'Group list ADD SUCCESS',
  GROUP_UPDATE = 'Group list UPDATE',
  GROUP_UPDATE_SUCCESS = 'Group list UPDATE SUCCESS',
  GROUP_DELETE = 'Group list DELETE',
  GROUP_DELETE_SUCCESS = 'Group list DELETE SUCCESS',
}

export const GetGroupsAction = createAction(GroupActionTypes.GROUP_LIST_GET);

export const LoadGroupsAction = createAction(
  GroupActionTypes.GROUP_LIST_SUCCESS,
  props<{ groups: iGroup[] }>(),
);

export const AddGroupAction = createAction(
  GroupActionTypes.GROUP_ADD,
  props<{ group: iGroup }>(),
);

export const AddGroupSuccessAction = createAction(
  GroupActionTypes.GROUP_ADD_SUCCESS,
  props<{ group: iGroup }>(),
);

export const UpdateGroupAction = createAction(
  GroupActionTypes.GROUP_UPDATE,
  props<{ group: iGroup }>(),
);

export const UpdateGroupSuccessAction = createAction(
  GroupActionTypes.GROUP_UPDATE_SUCCESS,
  props<{ group: iGroup }>(),
);

export const DeleteGroupAction = createAction(
  GroupActionTypes.GROUP_DELETE,
  props<{ group: iGroup }>(),
);

export const DeleteGroupSuccessAction = createAction(
  GroupActionTypes.GROUP_DELETE_SUCCESS,
  props<{ group: iGroup }>(),
);

export const ResetGroupStore = createAction('Reset Group Store');
