<button class="app-switcher__toggle" (click)="toggleSwitcher()" mat-mini-fab>
  <mat-icon>apps</mat-icon>
</button>

<div class="wrapper" [class.hide]="isHide">
  <div
    class="app-switcher animate__animated animate__fadeInDown"
    [class.hide]="isHide"
  >
    <button
      type="button"
      mat-icon-button
      aria-label="close"
      (click)="closeModal()"
      class="modal-box--close-button"
    >
      <mat-icon>close</mat-icon>
    </button>
    <div class="app-switcher__title">Switch to</div>
    <ul class="apps-list">
      <li *ngFor="let app of apps">
        <a (click)="routeToApp(app)" class="app-icon">
          <img [src]="app.icon" />
          <span class="app-name">
            {{ app.name }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
