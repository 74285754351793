import { UserService } from 'src/app/store/settings/user/service/user.service';
import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounce, debounceTime, Observable } from 'rxjs';
import { Avatars } from 'src/app/common/app-interfaces';
import { environment } from 'src/environments/environment';
import { IGroupTags } from '../common/interface';
import { ExpenseTrackerStoreState } from '../store/reducers';
import { getAllGroupTags } from '../store/reducers/group-tags/group-tags.selector';
import { getAllGroups } from '../store/reducers/group/group.selectors';
import { getAllUserIcons } from '../store/reducers/icon-uploads/icon-upload.selectors';
import { HttpService } from './http.service';

import * as freeIcons from '../../../../assets/jsons/free-icons.json';
import * as tagIcons from '../../../../assets/jsons/tag-icons.json';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  groupTags!: IGroupTags[];
  groupTagsIcons: any = {};
  userIcons: any = {};
  groupIcon: any = {};

  iconUrlByTagCache: { [key: string]: string } = {};
  miscellaneousIcon = Math.floor(Math.random() * 7);

  constructor(
    private actions: Actions,
    private store: Store<ExpenseTrackerStoreState>,
    private httpService: HttpService,
    private userService: UserService
  ) {
    // getting icons json file
    this.loadTagIcons();
  }

  loadTagIcons() {
    // setting licenses icons
    for (let tag in tagIcons) {
      tag = tag.toLowerCase();
      if (!this.iconUrlByTagCache[tag.toLowerCase()]) {
        this.iconUrlByTagCache[tag] =
          environment.iconResourceUrl.licensedIconUrl + tag + '.png';
      }
    }

    // setting unlicensed icons
    for (const tag in freeIcons) {
      if (!this.iconUrlByTagCache[tag]) {
        this.iconUrlByTagCache[tag] =
          environment.iconResourceUrl.freeIconUrl + tag + '.png';
      }
    }
  }

  loadUserIcons(): void {
    // getting user uploaded icons
    this.store
      .select(getAllUserIcons)
      .pipe(debounceTime(200))
      .subscribe((icons) => {
        const userIcons: any = {};
        icons.forEach((icon) => {
          if (icon.tag && icon.icon) {
            userIcons[icon.tag.toLowerCase()] =
              environment.iconResourceUrl.userIconUrl +
              this.userService.userUUIDs().get(icon.userId!) +
              '/icons/' +
              icon.icon;
          }
        });
        this.userIcons = userIcons;
      });

    // getting group tag icons
    this.store
      .select(getAllGroupTags)
      .pipe(debounceTime(200))
      .subscribe((groupTags) => {
        this.groupTags = groupTags;

        this.groupTags.forEach((gTags) => {
          if (gTags.isDeleted == 0 && gTags.extraInfo?.tagsInfo) {
            const tags = gTags.extraInfo?.tagsInfo;
            for (const key in tags) {
              if (tags[key].icon) {
                this.groupTagsIcons[key.toLowerCase()] =
                  environment.iconResourceUrl.userIconUrl +
                  this.userService.userUUIDs().get(gTags.userId!) +
                  '/group-tags/' +
                  tags[key].icon;
              }
            }
          }
        });
      });

    this.store
      .select(getAllGroups)
      .pipe(debounceTime(200))
      .subscribe((groups) => {
        groups.forEach((group) => {
          if (group.icon) {
            this.groupIcon[group.id] =
              environment.iconResourceUrl.userIconUrl +
              this.userService.userUUIDs().get(group.userId) +
              '/groups/' +
              group.icon;
          } else {
            this.groupIcon[group.id] = this.getIconForTag(
              group.name.toLowerCase()
            );
          }
        });
      });
  }

  getAvatars(): Observable<Avatars[]> {
    return this.httpService.getNonTrackerItem<Avatars[]>('user/get-avatars');
  }

  getGroupTagIcon(tag: string): string | undefined {
    if (this.groupTagsIcons) {
      if (
        this.groupTagsIcons[tag] !== undefined &&
        this.groupTagsIcons[tag] !== ''
      ) {
        return environment.memberGroupTagsAssetsUrl + this.groupTagsIcons[tag];
      }
    }
    return undefined;
  }

  getIconForTag(tag: string) {
    // first get user uploaded icons
    if (this.userIcons[tag]) {
      return this.userIcons[tag];
    }

    const licensedIconUrl = this.iconUrlByTagCache[tag];
    if (licensedIconUrl) {
      return licensedIconUrl;
    }
  }

  getIconForTagArray(tagArray: string[]) {
    let loadIconUrl = '';
    tagArray?.every((tag) => {
      tag = tag.toLowerCase();

      // check app icons
      const icon = this.getIconForTag(tag);
      if (icon) {
        loadIconUrl = icon;
        return false;
      }
      return true;
    });
    return loadIconUrl;
  }
}
