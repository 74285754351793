import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpService } from '../../../../mini-apps/expense-app/services/http.service';
import {
  ILogin,
  ILoginResponse,
  ISignUpUser,
  IUpdateAvatar,
} from '../../../../mini-apps/expense-app/common/interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { TrackerPermissions, IUser } from '../../../../common/app-interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService extends HttpService {
  userId!: string;
  uuid!: string;
  trackerPermissions: WritableSignal<TrackerPermissions[]> = signal<
    TrackerPermissions[]
  >([]);

  userUUIDs = computed(() => {
    const userIdToUUID = new Map<number, string>();
    this.trackerPermissions()?.forEach((x) => {
      userIdToUUID.set(x.userId, x.userUUID);
    });
    // now adding the current user
    userIdToUUID.set(+this.userId, this.uuid);
    return userIdToUUID;
  });

  userInitials = computed(() => {
    const userIdToInitials = new Map<number, string>();
    this.trackerPermissions()?.forEach((trackerUser) => {
      const firstName = trackerUser.firstName?.charAt(0) || '';
      const lastName = trackerUser.lastName?.charAt(0) || '';
      userIdToInitials.set(trackerUser.userId, `${firstName}${lastName}`);
    });
    return userIdToInitials;
  });

  LoginUser(data: ILogin): Observable<ILoginResponse> {
    return this.post<ILogin, ILoginResponse>('user/login', data).pipe(
      map((userInfo) => {
        if (userInfo.token) {
          environment.token = userInfo.token;
        }
        return userInfo;
      }),
    );
  }

  SignUpUser(data: ISignUpUser): Observable<ILoginResponse> {
    return this.post<ISignUpUser, ILoginResponse>('user/sign-up', data).pipe(
      map((userInfo) => {
        if (userInfo.token) {
          environment.token = userInfo.token;
        }
        return userInfo;
      }),
    );
  }

  UpdateUserAvatar(avatarUrl: string): Observable<IUser> {
    return this.post<IUpdateAvatar, IUser>('user/update-avatar', {
      avatarUrl,
    }).pipe(
      map((userInfo) => {
        return userInfo;
      }),
    );
  }

  AfterLoginSuccess(user: IUser) {
    this.userId = user.id.toString();
    this.uuid = user.uuid;
    this.trackerPermissions.set(user.trackerPermissions!);
    // setting user id and appending userId in the start
    // with each local-storage
    localStorage.setItem('userId', user.uuid.toString());

    // setting user folder for app to use

    // base user folder
    environment.memberIconAssetsUrl =
      environment.memberAssets + this.uuid + '/';

    // user group resources
    environment.memberGroupAssetsUrl =
      environment.memberIconAssetsUrl + 'groups/';

    // user group tag resources
    environment.memberGroupTagsAssetsUrl =
      environment.memberIconAssetsUrl + 'group-tags/';

    // user receipts resources
    environment.memberReceiptsUrl =
      environment.memberIconAssetsUrl + 'receipt/';

    // setting user last login update
    localStorage.setItem(this.uuid + '-last-login-date', new Date().toJSON());
    localStorage.setItem('last-user-email', user.username);
  }

  getTrackerPermissionUserByTrackerTypeId(
    trackerTypeId: number | null,
  ): number[] {
    let usersId = [];

    if (trackerTypeId) {
      usersId = this.trackerPermissions()
        ?.filter(
          (x) =>
            x.trackerTypeId === trackerTypeId &&
            x.isDeleted === 0 &&
            x.isActive === 1,
        )
        .map((x) => x.userId);
    } else {
      // dont filter by trackerTypeId
      usersId = this.trackerPermissions()
        ?.filter((x) => x.isDeleted === 0 && x.isActive === 1)
        .map((x) => x.userId);
    }

    // now remove duplicate id from the array
    return Array.from(new Set(usersId));
  }
}
