<div class="add-avatar-modal-box full-screen-modal-no-padding">
  <button
    type="button"
    aria-label="close"
    mat-dialog-close
    class="modal-box--close-button"
    mat-mini-fab
  >
    <mat-icon>close</mat-icon>
  </button>

  <div class="zero-record">
    <div class="info">
      <h2>Avatars</h2>
      <p>Pick avatars from below to replace yours.</p>
    </div>
    <div class="current-avatar">
      <img [src]="appIconUrl + currentAvatar" alt="" />
    </div>
  </div>
  <div class="avatars-list-container">
    @for (avatar of avatars$ | async; track avatar) {
      <span [ngClass]="{ selected: currentAvatar == avatar.avatarUrl }">
        <a href="javascript:;" (click)="updateAvatar(avatar.avatarUrl)">
          <img [src]="appIconUrl + avatar.avatarUrl" alt="" />
        </a>
      </span>
    }
  </div>
</div>
