import { Component, inject } from '@angular/core';
import { AppConfig, AppsCollection } from '../app-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-switcher',
  templateUrl: './app-switcher.component.html',
  styleUrl: './app-switcher.component.scss',
})
export class AppSwitcherComponent {
  apps = AppsCollection;
  isHide = true;
  router = inject(Router);

  toggleSwitcher() {
    this.isHide = !this.isHide;
  }

  closeModal() {
    this.isHide = true;
  }

  routeToApp(app: AppConfig) {
    // loop through app and remove app.cssClass from body tag and add new app.cssClass
    this.apps.forEach((app) => {
      document.body.classList.remove(app.cssClass);
    });
    document.body.classList.add(app.cssClass);
    this.router.navigate([app.route]);
  }
}
