import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  iGroup,
  iGroupEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import {
  AddGroupAction,
  AddGroupSuccessAction,
  DeleteGroupAction,
  DeleteGroupSuccessAction,
  GetGroupsAction,
  LoadGroupsAction,
  ResetGroupStore,
  UpdateGroupAction,
  UpdateGroupSuccessAction,
} from '../../actions/group.action';

export const GroupAdaptor: EntityAdapter<iGroup> =
  createEntityAdapter<iGroup>();
const initialGroupState: iGroupEntityState = GroupAdaptor.getInitialState();

const groupReducer = createReducer(
  initialGroupState,
  on(GetGroupsAction, (state) => ({ ...state })),
  on(LoadGroupsAction, (state, { groups: groups }) => {
    if (groups == null) return state;
    const rows: iGroup[] = [];
    groups.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return GroupAdaptor.addMany(rows, state);
    }
    return GroupAdaptor.upsertMany(rows, state);
  }),
  on(AddGroupAction, (state) => ({ ...state })),
  on(AddGroupSuccessAction, (state, { group }) => {
    if (group) {
      return GroupAdaptor.addOne(group, state);
    }
    return state;
  }),
  on(UpdateGroupAction, (state) => ({ ...state })),
  on(UpdateGroupSuccessAction, (state, { group }) => {
    if (group) {
      const updateGroup: Update<iGroup> = {
        id: group.id,
        changes: group,
      };
      return GroupAdaptor.updateOne(updateGroup, state);
    }
    return state;
  }),
  on(DeleteGroupAction, (state) => ({ ...state })),
  on(DeleteGroupSuccessAction, (state, { group }) => {
    if (group) {
      const deleteGroup: Update<iGroup> = {
        id: group.id,
        changes: group,
      };
      return GroupAdaptor.updateOne(deleteGroup, state);
    }
    return state;
  }),
  on(ResetGroupStore, (state) => GroupAdaptor.removeAll(state)),
);

export function GroupReducer(
  state: iGroupEntityState | undefined,
  action: Action,
): iGroupEntityState {
  return groupReducer(state, action);
}
