import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerCount: WritableSignal<number> = signal(0);

  constructor() {
    // check if spinner value is greater than 0 for more than 5 seconds
    // and then hide the spinner by resetting the value to 0
    setInterval(() => {
      if (this.spinnerCount() > 0) {
        this.spinnerCount.set(0);
      }
    }, 5000);
  }

  showSpinner() {
    this.spinnerCount.set(this.spinnerCount() + 1);
  }

  hideSpinner() {
    this.spinnerCount.set(this.spinnerCount() - 1);
  }
}
