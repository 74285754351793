import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GetUserAvatar } from 'src/app/store/settings/user/reducers/user.selector';
import { environment } from 'src/environments/environment';
import { IStoreState } from '../app-interfaces';
import { PickAvatarModalComponent } from '../pick-avatar-modal/pick-avatar-modal.component';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  appIconUrl = environment.appIconUrl;
  userAvatar: string = '';
  @Output() selectedAvatar: EventEmitter<string> = new EventEmitter();
  isModalOpened: boolean = false;

  constructor(
    private store: Store<IStoreState>,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.store
      .select(GetUserAvatar())
      .subscribe((name) => (this.userAvatar = name));
  }

  openPickAvatarModal() {
    const dialogRef = this.dialog.open(PickAvatarModalComponent, {
      panelClass: 'app-modal-box-pick-avatar-modal',
      hasBackdrop: true,
      height: '100%',
      width: '100%',
      data: {
        returnAvatar: false,
        currentAvatar: this.userAvatar,
      },
    });
    dialogRef.afterClosed().subscribe((avatar) => {
      if (avatar) {
        this.userAvatar = avatar;
        this.isModalOpened = false;
        this.selectedAvatar.emit(avatar);
      }
    });
  }
}
