import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IIconUpload, IIconUploadEntityState } from '../../../common/interface';
import { IconUploadAdaptor } from './icon-upload.reducer';

// get the selectors
const { selectAll, selectEntities } = IconUploadAdaptor.getSelectors();

export const selectIconUploadsFromState =
  createFeatureSelector<IIconUploadEntityState>('iconUpload');

export const getAllUserIcons = createSelector(
  selectIconUploadsFromState,
  selectAll,
);

export const getAllUserIconEntities = createSelector(
  selectIconUploadsFromState,
  selectEntities,
);

export const getIconByTag = (tag: string) =>
  createSelector(getAllUserIcons, (icons: IIconUpload[]) =>
    icons.filter((icon) => icon.tag.toLowerCase() == tag.toLowerCase()),
  );

export const getIconById = (id: number) =>
  createSelector(
    getAllUserIconEntities,
    (icons: Dictionary<IIconUpload>) => icons[id],
  );
