import { createEntityAdapter, EntityAdapter, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { IIconUpload, IIconUploadEntityState } from '../../../common/interface';
import {
  GetIconUploadAction,
  LoadIconUploadAction,
  CreateIconUploadAction,
  CreateIconUploadSuccessAction,
  UpdateIconUploadAction,
  UpdateIconUploadSuccessAction,
  DeleteIconUploadAction,
  DeleteIconUploadSuccessAction,
  ResetIconUploadStore,
} from '../../actions/icon-upload.action';

export const IconUploadAdaptor: EntityAdapter<IIconUpload> =
  createEntityAdapter<IIconUpload>();
const initialIconUploadState: IIconUploadEntityState =
  IconUploadAdaptor.getInitialState();

const iconUploadReducer = createReducer(
  initialIconUploadState,
  on(GetIconUploadAction, (state) => ({ ...state })),
  on(LoadIconUploadAction, (state, { icons }) => {
    if (icons == null) return state;
    const rows: IIconUpload[] = [];
    icons.forEach((item) => {
      if (item) {
        rows.push(item);
      }
    });

    if (rows.length == 0) return state;

    if (state?.ids?.length === 0) {
      return IconUploadAdaptor.addMany(rows, state);
    }
    return IconUploadAdaptor.upsertMany(rows, state);
  }),
  on(CreateIconUploadAction, (state) => ({ ...state })),
  on(CreateIconUploadSuccessAction, (state, { icon }) => {
    if (icon) {
      return IconUploadAdaptor.addOne(icon, state);
    }
    return state;
  }),
  on(UpdateIconUploadAction, (state) => ({ ...state })),
  on(UpdateIconUploadSuccessAction, (state, { icon }) => {
    if (icon) {
      const updateIconUpload: Update<IIconUpload> = {
        id: icon.id!,
        changes: icon,
      };
      return IconUploadAdaptor.updateOne(updateIconUpload, state);
    }
    return state;
  }),
  on(DeleteIconUploadAction, (state) => ({ ...state })),
  on(DeleteIconUploadSuccessAction, (state, { icon }) => {
    if (icon) {
      const deleteIconUpload: Update<IIconUpload> = {
        id: icon.id!,
        changes: icon,
      };
      return IconUploadAdaptor.updateOne(deleteIconUpload, state);
    }
    return state;
  }),
  on(ResetIconUploadStore, (state) => IconUploadAdaptor.removeAll(state)),
);

export function IconUploadReducer(
  state: IIconUploadEntityState | undefined,
  action: Action,
): IIconUploadEntityState {
  return iconUploadReducer(state, action);
}
