import { COMMA, ENTER } from '@angular/cdk/keycodes';

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteActivatedEvent,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Store } from '@ngrx/store';

import { IStoreState } from '../app-interfaces';
import { Observable } from 'rxjs';
import { take, startWith, map } from 'rxjs/operators';
import { getSingularAndPluralForms } from '../helper-functions';
import { environment } from '../../../environments/environment';
import { iTrack } from '../../mini-apps/expense-app/common/interface';
import { AutoSuggestionService } from '../../mini-apps/expense-app/services/auto-suggestion.service';
import { ExpenseEngineService } from '../../mini-apps/expense-app/services/expense-engine.service';
import { IconService } from '../../mini-apps/expense-app/services/icon.service';
import { ExpenseTrackerStoreState } from '../../mini-apps/expense-app/store/reducers';
import { getAllExpensesByUserGroupId } from '../../mini-apps/expense-app/store/reducers/expenses/expenses.selectors';
import { GetUserInfo } from '../../store/settings/user/reducers/user.selector';

@Component({
  selector: 'app-tag-form-input',
  templateUrl: './tag-form-input.component.html',
  styleUrls: ['./tag-form-input.component.scss'],
})
export class TagFormInputComponent implements OnInit, OnChanges {
  @ViewChild(MatAutocomplete) autoComplete!: MatAutocomplete;

  appIconsUrl = environment.appTagIconsUrl;
  iconsUrl = environment.iconsUrl;

  @Input() isPersistent = false;
  @Input() persistentKey!: string;
  @Input() placeHolderText = 'e.g. food, gas, movie night';

  @Input() startingTags!: string[];

  @Output() addUpdate = new EventEmitter();
  @Output() lastPurchasesPrice = new EventEmitter();

  selectable = true;
  removable = true;
  addOnBlur = false;
  tags: string[] = [];

  tagInputControl = new FormControl();

  filteredSavedTags!: Observable<string[]>;

  isMatAutoCompleteOpened = false;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  allExpenses!: iTrack[];
  isMatOptionSelected: boolean = false;
  userGroupId!: number;

  constructor(
    private store: Store<ExpenseTrackerStoreState>,
    private expenseAppSettings: Store<IStoreState>,
    private autoSuggestionService: AutoSuggestionService,
    private expenseEngineService: ExpenseEngineService,
    public iconService: IconService
  ) {
    this.expenseAppSettings
      .select(GetUserInfo)
      .pipe(take(1))
      .subscribe(
        (user) =>
          (this.userGroupId =
            user.extraInfo.settings.expenseTracker.defaultUserGroupId)
      );
    // optionSelected
    const sub = this.store
      .select(getAllExpensesByUserGroupId(this.userGroupId))
      .pipe(take(1))
      .subscribe((expenses) => {
        this.allExpenses = expenses;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const tags = changes['startingTags']?.currentValue;
    if (tags && tags?.length > 0) {
      tags.forEach((tag: string) =>
        this.add({
          value: tag,
          input: undefined as any,
          chipInput: undefined as any,
        })
      );
      this.getLastPrice();
    } else {
      this.clearTags();
    }
  }

  clearTags() {
    const existingTags = [...this.tags];
    existingTags.forEach((tag) => {
      this.remove(tag);
    });
  }

  ngOnInit(): void {
    // if load data from storage flag is on, get the data
    if (this.persistentKey) {
      this.persistentKey =
        localStorage.getItem('userId') + '-' + this.persistentKey;
    }

    this.setPersistentData();

    this.filteredSavedTags = this.tagInputControl.valueChanges.pipe(
      startWith(''),
      map((value) => (value && value.length > 1 ? this._filter(value) : []))
    );
  }

  setPersistentData() {
    if (!this.persistentKey) return;

    const storedValue =
      this.expenseEngineService.getChartFiltersFromLocalStorage();
    if (storedValue.length > 0) {
      this.tags = storedValue;
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // if value is enter and autocomplete is open
    if (value && this.autoComplete?.isOpen && this.isMatOptionSelected) {
      event.chipInput?.clear();
      return;
    }

    // Add our tags
    if (value && this.tags.indexOf(value) < 0) {
      this.tags.push(value);
    }

    if (this.persistentKey) {
      this.expenseEngineService.setChartFiltersFromLocalStorage(this.tags);
    }

    // Clear the input value
    event.chipInput?.clear();
    this.addUpdate.emit(this.tags);
    this.isMatOptionSelected = false;
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    if (this.persistentKey) {
      this.expenseEngineService.setChartFiltersFromLocalStorage(this.tags);
    }
    this.getLastPrice();
    this.addUpdate.emit(this.tags);
  }

  optionSelected(autoComplete: MatAutocompleteSelectedEvent) {
    this.tagInputControl.setValue('');
    const value = autoComplete.option.value;

    if (value.indexOf('|') != -1) {
      autoComplete.option.value
        .split('|')
        .forEach((autoCompleteTag: string) => {
          if (autoCompleteTag && this.tags.indexOf(autoCompleteTag) < 0) {
            this.tags.push(autoCompleteTag);
          }
        });
    } else {
      if (value && this.tags.indexOf(value) < 0) {
        this.tags.push(value);
      }
    }

    this.persistentKey &&
      this.expenseEngineService.setChartFiltersFromLocalStorage(this.tags);
    this.getLastPrice();
    this.isMatOptionSelected = false;
    this.addUpdate.emit(this.tags);
  }

  getLastPrice() {
    const searchFor = this.tags.join('|');
    const lastPrices: number[] = [];
    const result = [];
    for (let i = this.allExpenses.length - 1; i >= 0; i--) {
      if (
        this.allExpenses[i].tags == searchFor &&
        !lastPrices.includes(this.allExpenses[i].trackerValue!)
      ) {
        result.push(this.allExpenses[i]);
        lastPrices.push(this.allExpenses[i].trackerValue!);
      }
      if (result.length > 3) {
        i = 0;
      }
    }
    this.lastPurchasesPrice.emit(result);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    // Get both singular and plural forms of the filter value
    const [singular, plural] = getSingularAndPluralForms(filterValue);

    const result = this.autoSuggestionService.suggestionsData.filter(
      (option) => {
        const lowerOption = option.toLowerCase();
        return lowerOption.includes(singular) || lowerOption.includes(plural);
      }
    );
    return result.slice(0, 7);
  }

  optionActivated($event: MatAutocompleteActivatedEvent) {
    // this.isMatOptionSelected = true;
  }

  setTags(tags: string[]) {
    this.tags = tags;
    this.getLastPrice();
  }
}
