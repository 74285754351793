import { Component, Input, OnInit } from '@angular/core';

export enum MessageType {
  error = 1,
  success = 2,
  warning = 3,
  announcement = 4,
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() type!: MessageType;
  @Input() message!: string;
  @Input() showMessageTitle: boolean = true;

  @Input() show: boolean = true;

  MessageType = MessageType;
  constructor() {}

  ngOnInit(): void {}
}
