import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStoreState } from 'src/app/common/app-interfaces';
import { ToArray } from 'src/app/common/helper-functions';
import { GetUserInfo } from 'src/app/store/settings/user/reducers/user.selector';
import * as data from '../../../../assets/mini-apps/expense-tracker/suggestions.json';
import { Trackers } from '../common/enum';
import { ExpenseTrackerStoreState } from '../store/reducers';
import { getAllExpensesByTrackTypeId } from '../store/reducers/expenses/expenses.selectors';

@Injectable({
  providedIn: 'root',
})
export class AutoSuggestionService {
  suggestionsData: string[] = [];
  userGroupId!: number;

  constructor(
    private store: Store<ExpenseTrackerStoreState>,
    private expenseAppSettings: Store<IStoreState>,
  ) {
    this.expenseAppSettings
      .select(GetUserInfo)
      .subscribe(
        (user) =>
          (this.userGroupId =
            user.extraInfo.settings.expenseTracker.defaultUserGroupId),
      );

    this.setAutoSuggestionFromJson();
    this.refreshAutoComplete();
  }

  refreshAutoComplete() {
    let savedTags: any = [];
    const sub = this.store
      .select(getAllExpensesByTrackTypeId(Trackers.expense, this.userGroupId))
      .subscribe((expenses) => {
        ToArray(expenses, 'tags', savedTags);
      });

    // Count tag frequencies
    const tagCounts: { [tag: string]: number } = {};
    savedTags.concat(this.suggestionsData).forEach((tag: string) => {
      if (!tagCounts[tag]) {
        tagCounts[tag] = 0;
      }
      tagCounts[tag]++;
    });

    // Create an array from the tag counts and sort by frequency
    const sortedTags = Object.keys(tagCounts).sort(
      (a, b) => tagCounts[b] - tagCounts[a],
    );

    this.suggestionsData = sortedTags;
  }

  setAutoSuggestionFromJson() {
    const tagList: any = {};
    const suggestions = data as any;
    for (const key in suggestions) {
      const item: { addKeyToSuggestions: string; data: string[] } =
        suggestions[key];

      const suggestionKey = item.addKeyToSuggestions?.toLowerCase();

      if (item.data?.forEach) {
        item.data.forEach((word) => {
          word = word?.toLowerCase() || '';

          if (suggestionKey !== '') {
            const wordSuggest = word + '|' + suggestionKey;

            if (tagList[wordSuggest] == undefined) {
              this.suggestionsData.push(wordSuggest);
              tagList[wordSuggest] = wordSuggest;
            }
          } else {
            if (tagList[word] == undefined) {
              this.suggestionsData.push(word);
            }
          }
        });
      }
    }
  }
}
