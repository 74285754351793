import { Directive, AfterViewInit, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appAnimateRows]',
})
export class AnimateDelayDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}
  ngOnInit(): void {
    const index = this.elementRef.nativeElement.getAttribute('data-index');
    this.elementRef.nativeElement.style.setProperty(
      '--animate-duration',
      `${(index * 10) / 100}s`,
    );
  }
}
