import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCommonModule } from '@angular/material/core';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSliderModule } from '@angular/material/slider';

import { SpinnerComponent } from './spinner/spinner.component';
import { DateSuffixPipe } from './date-ordinal-pipe/date-ordinal.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { LineChartComponent } from './chart/line-chart/line-chart.component';
import { BarChartComponent } from './chart/bar-chart/bar-chart.component';
import { MessageComponent } from './message/message.component';
import { TagFormInputComponent } from './tag-form-input/tag-form-input.component';
import { UsernameComponent } from './username/username.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { PickAvatarModalComponent } from './pick-avatar-modal/pick-avatar-modal.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { CircularPercentageComponent } from './circular-percentage/circular-percentage.component';
import { TagChartDirective } from './tag-chart/tag-chart.directive';
import { AnimateDelayDirective } from './animate-delay.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppSwitcherComponent } from './app-switcher/app-switcher.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    DateSuffixPipe,
    LineChartComponent,
    BarChartComponent,
    MessageComponent,
    TagFormInputComponent,
    UsernameComponent,
    UserAvatarComponent,
    PickAvatarModalComponent,
    CircularPercentageComponent,
    TagChartDirective,
    AnimateDelayDirective,
    AppSwitcherComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCommonModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatRippleModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    RouterModule,
    RxReactiveFormsModule,
    MatSliderModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    MatCommonModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatRippleModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatBottomSheetModule,
    MatSliderModule,
    TagChartDirective,
    SpinnerComponent,
    DateSuffixPipe,
    LineChartComponent,
    BarChartComponent,
    MessageComponent,
    TagFormInputComponent,
    UsernameComponent,
    PickAvatarModalComponent,
    UserAvatarComponent,
    CircularPercentageComponent,
    AnimateDelayDirective,
    AppSwitcherComponent,
  ],
  providers: [CurrencyPipe],
})
export class CommonComponentsModule {}
