import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-circular-percentage',
  templateUrl: './circular-percentage.component.html',
  styleUrls: ['./circular-percentage.component.scss'],
})
export class CircularPercentageComponent implements OnInit, OnChanges {
  @Input() percentage!: number;
  barColor!: string;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.setBarColor();
  }

  ngOnInit(): void {
    this.setBarColor();
  }

  setBarColor() {
    if (this.percentage >= 90) {
      this.barColor = `conic-gradient(#F20056 ${this.percentage}%, rgb(239 239 239 / 8%) 0)`;
    } else if (this.percentage >= 65 && this.percentage < 90) {
      this.barColor = `conic-gradient(#FF9900 ${this.percentage}%, rgb(239 239 239 / 8%) 0)`;
    } else {
      this.barColor = `conic-gradient(#92CD00 ${this.percentage}%, rgb(239 239 239 / 8%) 0)`;
    }
  }
}
