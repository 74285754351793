import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GetUserName } from 'src/app/store/settings/user/reducers/user.selector';
import { IStoreState } from '../app-interfaces';

@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
})
export class UsernameComponent implements OnInit {
  firstName: string = '';
  constructor(private store: Store<IStoreState>) {}
  ngOnInit(): void {
    this.store
      .select(GetUserName())
      .subscribe((name) => (this.firstName = name));
  }
}
