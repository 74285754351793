<div class="wrap-circles" [attr.data-percentage]="percentage">
  <div
    class="circle"
    [ngClass]="{
      red: percentage >= 90,
      orange: percentage >= 65 && percentage < 90,
      green: percentage > 0 && percentage < 65,
    }"
    [ngStyle]="{ backgroundImage: barColor }"
  >
    <div class="inner">
      <ng-content></ng-content>
    </div>
  </div>
</div>
