import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  iTrack,
  iTrackEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import { ExpensesAdaptor } from './expenses.reducer';

// get the selectors
const { selectAll, selectEntities } = ExpensesAdaptor.getSelectors();

// select the array of users
const allExpenses = selectAll;
const allEntities = selectEntities;

export const selectExpensesFromState =
  createFeatureSelector<iTrackEntityState>('trackers');

export const getAllExpenses = createSelector(
  selectExpensesFromState,
  allExpenses
);

export const getAllExpensesEntities = createSelector(
  selectExpensesFromState,
  allEntities
);

export const getExpenseById = (id: number) =>
  createSelector(
    getAllExpensesEntities,
    (state: Dictionary<iTrack>) => state[id]
  );

export const getAllExpensesByUserGroupId = (userGroupId?: number) =>
  createSelector(getAllExpenses, (tracks: iTrack[]) =>
    tracks.filter((e) => e.userGroupId == userGroupId)
  );

export const getAllExpensesByTrackTypeId = (
  typeId: number,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByUserGroupId(userGroupId),
    (trackers: iTrack[]) => trackers.filter((e) => e.trackerTypeId === typeId)
  );

// namespace: yearly filters
export const getAllExpensesByYear = (
  typeId: number,
  year: number,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByTrackTypeId(typeId, userGroupId),
    (trackers: iTrack[]) => trackers.filter((e) => e.year === year)
  );
// end namespace

// namespace: monthly filters
export const getAllExpensesByMonth = (
  typeId: number,
  month: number,
  year: number,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByTrackTypeId(typeId, userGroupId),
    (trackers: iTrack[]) => {
      return trackers.filter(
        (e) => e.month === month && e.year === year && e.isDeleted == 0
      );
    }
  );

export const getAllExpensesValueTotalByMonth = (
  typeId: number,
  month: number,
  year: number,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByMonth(typeId, month, year, userGroupId),
    (trackers: iTrack[]) =>
      trackers.reduce((total, tracks) => total + +tracks.trackerValue!, 0)
  );

export const getExpensesByGroupIdInMonth = (
  typeId: number,
  month: number,
  year: number,
  groupId: number,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByMonth(typeId, month, year, userGroupId),
    (trackers: iTrack[]) =>
      trackers.filter((tracks) => tracks.groupId === groupId)
  );

export const getExpensesByGroupIdForDayInMonth = (
  typeId: number,
  month: number,
  year: number,
  groupId: number,
  day: number,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByMonth(typeId, month, year, userGroupId),
    (trackers: iTrack[]) => trackers.filter((tracks) => tracks.day === day)
  );

export const getExpensesByForDayInMonth = (
  typeId: number,
  month: number,
  year: number,
  day: number,
  userGroupId: number,
  tag?: string,
  groupTagged?: boolean,
  groupId?: number
) =>
  createSelector(
    getAllExpensesByMonth(typeId, month, year, userGroupId),
    (trackers: iTrack[]) =>
      trackers.filter((track) => {
        groupTagged = groupTagged || false;

        // if its a groupTagged query loop the shit
        // and return the track if any of the tags matches
        if (groupTagged) {
          let tagFound = false;
          tag!.split(', ').forEach((tag) => {
            if (tagFound == false) {
              tagFound =
                track.tags!.toLowerCase().indexOf(tag.toLowerCase()) >= 0;
            }
          });

          return track.day === day && tagFound;
        }

        // if its a group id
        // and return the track if group id matches with parameter
        if (groupId! > 0) {
          return track.day === day && track.groupId == groupId;
        }

        if (tag) {
          return (
            track.day === day &&
            track.tags!.toLowerCase().indexOf(tag.toLowerCase()) >= 0
          );
        }

        return track.day === day;
      })
  );

export const getExpensesForMonthByTag = (
  typeId: number,
  month: number,
  year: number,
  tag: string,
  userGroupId: number
) =>
  createSelector(
    getAllExpensesByMonth(typeId, month, year, userGroupId),
    (trackers: iTrack[]) =>
      trackers.filter((tracks) => tracks.tags!.indexOf(tag) >= 0)
  );
// end monthly filters
