import { ExpenseAppNav } from '../mini-apps/expense-app/common/enum';
import { HabitTrackerRoutes } from '../mini-apps/habit-tracker/models/habit-app.model';

export const AppErrorList = {
  generic: 'Error has occurred reaching server, please try again in few weeks',
  HttpErrorResponse: 'Server is not available.',
};

export const AppsCollection: AppConfig[] = [
  {
    name: 'Expense Tracker',
    tagLine: 'Track Your Spending',
    icon: 'assets/app-icons/app-switcher-icons/expense-tracker.png',
    route: ExpenseAppNav.root,
    cssClass: 'expense-tracker-app',
  },
  {
    name: 'Habit Tracker',
    tagLine: 'Habit. Goals. Prosper.',
    icon: 'assets/app-icons/app-switcher-icons/habit-tracker.png',
    route: HabitTrackerRoutes.root,
    cssClass: 'habit-tracker-app',
  },
];

export type AppConfig = {
  name: string;
  icon: string;
  route: string;
  cssClass: string;
  tagLine: string;
};

// export name of the app as enum
export enum AppName {
  ExpenseTracker = 'Expense Tracker',
  HabitTracker = 'Habit Tracker',
}
