<mat-chip-grid #chipGrid aria-label="Tag selection">
  @for (tag of tags; track tag) {
    <mat-chip-row (removed)="remove(tag)" class="input-tag-chip">
      <img
        class="tag-icon"
        [src]="appIconsUrl + tag + '.png'"
        alt=""
        onload="this.setAttribute('icon-loaded',true);"
        icon-loaded=""
        onerror="this.setAttribute('icon-loaded',false);"
      />
      <img
        class="tag-icon"
        [src]="iconsUrl + tag.toLowerCase() + '.png'"
        alt=""
      />
      {{ tag }}
      <button matChipRemove [attr.aria-label]="'remove ' + tag">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  }
</mat-chip-grid>
<input
  [placeholder]="placeHolderText ? placeHolderText : 'Add a tag'"
  [formControl]="tagInputControl"
  [matChipInputFor]="chipGrid"
  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
  [matChipInputAddOnBlur]="addOnBlur"
  (matChipInputTokenEnd)="add($event)"
  [matAutocomplete]="auto"
  enterkeyhint="done"
/>

<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="optionSelected($event)"
  (optionActivated)="optionActivated($event)"
  [autoActiveFirstOption]="false"
  class="tag-autocomplete"
>
  @for (tags of filteredSavedTags | async; track tags; let i = $index) {
    <mat-option [value]="tags">
      @for (tag of tags.split("|").slice(0, 4); track tag) {
        <span class="app-tags">
          <img [src]="iconService.getIconForTag(tag.toLowerCase())" alt="" />
          {{ tag }}
        </span>
      }
    </mat-option>
  }
</mat-autocomplete>
