import { Component, Input } from '@angular/core';
import { LineChartDataset } from '../../app-interfaces';

declare var Chart: any;

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
})
export class LineChartComponent {
  @Input() dataset!: LineChartDataset[];
  @Input() labels!: string[];
  @Input() toolTipCallBack!: Function;

  // holds chart instance
  chart: any;

  // random id generated just in case we have multiple charts in one page.
  randomId: number = Math.floor(Math.random() * 99999 + 1);

  buildChart(): void {
    const labels = this.labels;
    const data = {
      labels: labels,
      datasets: this.dataset,
    };
    const config = {
      type: 'line',
      data: data,
      options: {
        plugins: {
          legend: {
            labels: {
              color: '#efefef',
            },
          },
          tooltip: {
            callbacks: {
              label: this.toolTipCallBack,
            },
          },
        },
      },
    };
    Chart.defaults.color = '#ececec';

    if (this.chart == undefined) {
      this.chart = new Chart(
        document.getElementById('line-chart-' + this.randomId.toString()),
        config,
      );
    } else {
      this.chart.data.labels = this.labels;
      this.chart.data.datasets = this.dataset;
      this.chart.update();
    }
  }
}
