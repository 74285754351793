import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IGroupTags, IGroupTagsEntityState } from '../../../common/interface';
import { GroupTagAdaptors } from './group-tags.reducer';

// get the selectors
const { selectAll, selectEntities } = GroupTagAdaptors.getSelectors();

export const selectGroupTagsFromState =
  createFeatureSelector<IGroupTagsEntityState>('groupTags');
export const getAllGroupTags = createSelector(
  selectGroupTagsFromState,
  selectAll,
);
export const getAllGroupTagsEntities = createSelector(
  selectGroupTagsFromState,
  selectEntities,
);

export const getGroupTagsByName = (groupName: string) =>
  createSelector(getAllGroupTags, (groupTags) =>
    groupTags.filter((group) => group.groupName === groupName),
  );

export const getGroupById = (groupTagId: number) =>
  createSelector(
    getAllGroupTagsEntities,
    (groupTags: Dictionary<IGroupTags>) => groupTags[groupTagId],
  );
