{
  "fruits": {
    "addKeyToSuggestions": "fruits|food",
    "data": [
      "apple",
      "apricot",
      "avocado",
      "banana",
      "bell pepper",
      "bilberry",
      "blackberry",
      "blackcurrant",
      "blood orange",
      "blueberry",
      "boysenberry",
      "breadfruit",
      "canary melon",
      "cantaloupe",
      "cherimoya",
      "cherry",
      "chili pepper",
      "clementine",
      "cloudberry",
      "coconut",
      "cranberry",
      "cucumber",
      "currant",
      "damson",
      "date",
      "dragonfruit",
      "durian",
      "eggplant",
      "elderberry",
      "feijoa",
      "fig",
      "goji berry",
      "gooseberry",
      "grape",
      "grapefruit",
      "guava",
      "honeydew",
      "huckleberry",
      "jackfruit",
      "jambul",
      "jujube",
      "kiwi fruit",
      "kumquat",
      "lemon",
      "lime",
      "loquat",
      "lychee",
      "mandarine",
      "mango",
      "mulberry",
      "nectarine",
      "nut",
      "olive",
      "orange",
      "pamelo",
      "papaya",
      "passionfruit",
      "peach",
      "pear",
      "persimmon",
      "physalis",
      "pineapple",
      "plum",
      "pomegranate",
      "pomelo",
      "purple mangosteen",
      "quince",
      "raisin",
      "rambutan",
      "raspberry",
      "redcurrant",
      "rock melon",
      "salal berry",
      "satsuma",
      "star fruit",
      "strawberry",
      "tamarillo",
      "tangerine",
      "tomato",
      "ugli fruit",
      "watermelon"
    ]
  },
  "vegetables": {
    "addKeyToSuggestions": "vegetables|food",
    "data": [
      "acorn squash",
      "alfalfa sprout",
      "amaranth",
      "anise",
      "artichoke",
      "arugula",
      "asparagus",
      "aubergine",
      "azuki bean",
      "banana squash",
      "basil",
      "bean sprout",
      "beet",
      "black bean",
      "black-eyed pea",
      "bok choy",
      "borlotti bean",
      "broad beans",
      "broccoflower",
      "broccoli",
      "brussels sprout",
      "butternut squash",
      "cabbage",
      "calabrese",
      "caraway",
      "carrot",
      "cauliflower",
      "cayenne pepper",
      "celeriac",
      "celery",
      "chamomile",
      "chard",
      "chayote",
      "chickpea",
      "chives",
      "cilantro",
      "collard green",
      "corn",
      "corn salad",
      "courgette",
      "cucumber",
      "daikon",
      "delicata",
      "dill",
      "eggplant",
      "endive",
      "fennel",
      "fiddlehead",
      "frisee",
      "garlic",
      "gem squash",
      "ginger",
      "green bean",
      "green pepper",
      "habanero",
      "herbs and spice",
      "horseradish",
      "hubbard squash",
      "jalapeno",
      "jerusalem artichoke",
      "jicama",
      "kale",
      "kidney bean",
      "kohlrabi",
      "lavender",
      "leek ",
      "legume",
      "lemon grass",
      "lentils",
      "lettuce",
      "lima bean",
      "mamey",
      "mangetout",
      "marjoram",
      "mung bean",
      "mushroom",
      "mustard green",
      "navy bean",
      "new zealand spinach",
      "nopale",
      "okra",
      "onion",
      "oregano",
      "paprika",
      "parsley",
      "parsnip",
      "patty pan",
      "pea",
      "pinto bean",
      "potato",
      "pumpkin",
      "radicchio",
      "radish",
      "rhubarb",
      "rosemary",
      "runner bean",
      "rutabaga",
      "sage",
      "scallion",
      "shallot",
      "skirret",
      "snap pea",
      "soy bean",
      "spaghetti squash",
      "spinach",
      "squash",
      "sweet potato",
      "tabasco pepper",
      "taro",
      "tat soi",
      "thyme",
      "topinambur",
      "tubers",
      "turnip",
      "wasabi",
      "water chestnut",
      "watercress",
      "white radish",
      "yam",
      "zucchini"
    ]
  },
  "condiments": {
    "addKeyToSuggestions": "condiments|food",
    "data": [
      "Aioli",
      "Ajvar",
      "Amba",
      "Barbecue sauce",
      "Biber salçası",
      "Brown mustard",
      "Chili peppers",
      "Chili sauce",
      "Chili oil",
      "Chimichurri",
      "Chutney",
      "Cocktail sauce",
      "Colo-colo",
      "Crushed red pepper",
      "Dabu-dabu",
      "Dijon ketchup",
      "Dijon mustard",
      "Dip",
      "Fish paste",
      "Fish sauce",
      "Fritessaus",
      "Fruit preserves",
      "Fry sauce",
      "Gochujang",
      "Guacamole",
      "Honey dill",
      "Horseradish",
      "Hot mustard",
      "Hot sauce",
      "Skhug",
      "Kachumbari",
      "Kachumber",
      "Ketchup",
      "Fruit ketchup",
      "Banana ketchup",
      "Curry ketchup",
      "Kimchi",
      "Khrenovina",
      "Kyopolou",
      "Mayonnaise",
      "Mignonette sauce",
      "Milkette",
      "Monkey gland sauce",
      "Muhammara",
      "Mumbo sauce",
      "Murri",
      "Mushroom ketchup",
      "Mustard",
      "Mostarda",
      "Mustard oil",
      "Tewkesbury mustard",
      "Turun sinappi",
      "Nutritional yeast",
      "Olive oil",
      "Pepper jelly",
      "Pesto",
      "Piccalilli",
      "Pickled fruit",
      "Pico de gallo",
      "Mango pickle",
      "Pickled cucumber",
      "Pickled onion",
      "Pickled pepper",
      "Pinđur",
      "Popcorn seasoning",
      "Relish",
      "Chrain",
      "Ljutenica",
      "Remoulade",
      "Salad cream",
      "Salad dressing",
      "Salmoriglio",
      "Salsa",
      "Salsa golf",
      "Salt",
      "Salt and pepper",
      "Sambal",
      "Sauerkraut",
      "Sesame oil",
      "Skyronnes",
      "Soy sauce",
      "Sriracha",
      "Steak sauce",
      "Sumbala",
      "Sweet chilli sauce",
      "Syrup",
      "Tartar sauce",
      "Tekka",
      "Teriyaki sauce",
      "Tkemali",
      "Toum",
      "Vinegar",
      "Black vinegar",
      "Vincotto",
      "Vino cotto",
      "Watermelon rind preserves",
      "XO sauce",
      "Za'atar",
      "Zacuscă"
    ]
  },
  "breads": {
    "addKeyToSuggestions": "breads|food",
    "data": [
      "bagel",
      "baguette",
      "boule",
      "brioche",
      "casalinga",
      "chapati",
      "ciabatta",
      "cornbread",
      "crumpet",
      "epi",
      "ficelle",
      "flatbread",
      "focaccia",
      "fruit bread",
      "hamburger bun",
      "hardtack",
      "lavash",
      "matzoh",
      "muffin",
      "naan",
      "pain au levain",
      "pain de mie",
      "pane d'olive",
      "paratha",
      "pita",
      "potato bread",
      "pretzel",
      "pumpernickel",
      "roti",
      "rugbrød",
      "rye",
      "scone",
      "soda bread",
      "sourdough",
      "tortilla"
    ]
  },
  "pastries": {
    "addKeyToSuggestions": "pastries|food",
    "data": [
      "baklava",
      "bomboloni",
      "canelé",
      "cinnamon roll",
      "croissant",
      "cronut",
      "doughnut",
      "éclair",
      "gulab jamun",
      "kouign-amann",
      "mille-feuille",
      "morning bun",
      "pain au chocolat",
      "pastel de nata",
      "profiterole",
      "rugelach",
      "shortcake",
      "stollen",
      "strudel",
      "toaster strudel"
    ]
  },
  "herbs": {
    "addKeyToSuggestions": "herbs|food",
    "data": [
      "Angelica",
      "Basil",
      "Holy Basil",
      "Thai Basil",
      "Bay leaf",
      "Indian Bay leaf",
      "Boldo",
      "Borage",
      "Chervil",
      "Chives",
      "Garlic Chives",
      "Cicely",
      "Coriander leaf",
      "Cilantro",
      "Bolivian Coriander",
      "Vietnamese Coriander",
      "Culantro",
      "Cress",
      "Curry leaf",
      "Dill",
      "Epazote",
      "Hemp",
      "Hoja santa",
      "Houttuynia cordata",
      "Hyssop",
      "Jimbu",
      "Kinh gioi",
      "Lavender",
      "Lemon balm",
      "Lemon grass",
      "Lemon myrtle",
      "Lemon verbena",
      "Limnophila aromatica",
      "Lovage",
      "Marjoram",
      "Mint",
      "Mugwort",
      "Mitsuba",
      "Oregano",
      "Parsley",
      "Perilla",
      "Rosemary",
      "Rue",
      "Sage",
      "Savory",
      "Sansho",
      "Shiso",
      "Sorrel",
      "Tarragon",
      "Thyme",
      "Woodruff"
    ]
  },
  "spices": {
    "addKeyToSuggestions": "spices|food",
    "data": [
      "Aonori",
      "Ajwain",
      "Allspice",
      "Amchoor",
      "Anise",
      "Star Anise",
      "Asafoetida",
      "Camphor",
      "Caraway",
      "Cardamom",
      "Black Cardamom",
      "Cassia",
      "Celery powder",
      "Celery seed",
      "Charoli",
      "Chenpi",
      "Cinnamon",
      "Clove",
      "Coriander seed",
      "Cubeb",
      "Cumin",
      "Nigella sativa",
      "Bunium persicum",
      "Dill",
      "Dill seed",
      "Fennel",
      "Fenugreek",
      "Fingerroot",
      "Greater Galangal",
      "Lesser Galangal",
      "Garlic",
      "Ginger",
      "Aromatic Ginger",
      "Golpar",
      "Grains of Paradise",
      "Grains of Selim",
      "Horseradish",
      "Juniper berry",
      "Kokum",
      "Korarima",
      "Dried Lime",
      "Liquorice",
      "Litsea cubeba",
      "Mace",
      "Mango-ginger",
      "Mastic",
      "Mahlab",
      "Black Mustard",
      "Brown Mustard",
      "White Mustard",
      "Nigella",
      "Njangsa",
      "Nutmeg",
      "Alligator Pepper",
      "Brazilian Pepper",
      "Chili Pepper",
      "Cayenne pepper",
      "Paprika",
      "Long Pepper",
      "Peruvian Pepper",
      "East Asian Pepper",
      "Sichuan Pepper",
      "Sansho",
      "Tasmanian Pepper",
      "Black Peppercorn",
      "Green Peppercorn",
      "White Peppercorn",
      "Pomegranate seed",
      "Poppy seed",
      "Radhuni",
      "Rose",
      "Saffron",
      "Salt",
      "Sarsaparilla",
      "Sassafras",
      "Sesame",
      "Shiso",
      "Sumac",
      "Tamarind",
      "Tonka bean",
      "Turmeric",
      "Uzazi",
      "Vanilla",
      "Voatsiperifery",
      "Wasabi",
      "Yuzu",
      "Zedoary",
      "Zereshk",
      "Zest"
    ]
  },
  "mixtures": {
    "addKeyToSuggestions": "mixtures|food",
    "data": [
      "Adjika",
      "Advieh",
      "Baharat",
      "Beau Monde seasoning",
      "Berbere",
      "Bouquet garni",
      "Buknu",
      "Chaat masala",
      "Chaunk",
      "Chili powder",
      "Crab boil",
      "Curry powder",
      "Doubanjiang",
      "Douchi",
      "Duqqa",
      "Fines herbes",
      "Five-spice powder",
      "Garam masala",
      "Garlic powder",
      "Garlic salt",
      "Gochujang",
      "Harissa",
      "Hawaij",
      "Herbes de Provence",
      "Idli podi",
      "Jamaican jerk spice",
      "Khmeli suneli",
      "Lemon pepper",
      "Mitmita",
      "Mixed spice",
      "Montreal steak seasoning",
      "Mulling spices",
      "Old Bay Seasoning",
      "Onion powder",
      "Panch phoron",
      "Persillade",
      "Powder-douce",
      "Pumpkin pie spice",
      "Qâlat daqqa",
      "Quatre épices",
      "Ras el hanout",
      "Recado rojo",
      "Sharena sol",
      "Shichimi",
      "Tabil",
      "Tandoori masala",
      "Vadouvan",
      "Yuzukosho",
      "Za'atar"
    ]
  },
  "clothes": {
    "addKeyToSuggestions": "clothes",
    "data": [
      "baby grow",
      "ball gown",
      "belt",
      "bikini",
      "blazer",
      "blouse",
      "boots",
      "bow tie",
      "boxers",
      "bra",
      "bra & knicker set",
      "briefs",
      "camisole",
      "cap",
      "cardigan",
      "cargos",
      "catsuit",
      "chemise",
      "coat",
      "corset",
      "cravat",
      "cufflinks",
      "cummerbund",
      "dinner jacket",
      "dress",
      "dressing gown",
      "dungarees",
      "fleece",
      "gloves",
      "hat",
      "hoody",
      "jacket",
      "jeans",
      "jogging suit",
      "jumper",
      "kaftan",
      "kilt",
      "knickers",
      "kurta",
      "lingerie",
      "nightgown",
      "nightwear",
      "pants",
      "pashmina",
      "polo shirt",
      "poncho",
      "pyjamas",
      "raincoat",
      "robe",
      "romper",
      "sandals",
      "sarong",
      "scarf",
      "shawl",
      "shellsuit",
      "shirt",
      "shoes",
      "shorts",
      "skirt",
      "slacks",
      "slippers",
      "socks",
      "stockings",
      "suit",
      "sunglasses",
      "sweater",
      "sweatshirt",
      "swimming costume",
      "swimming shorts",
      "swimming trunks",
      "swimwear",
      "t-shirt",
      "tailcoat",
      "tankini",
      "thong",
      "tie",
      "tights",
      "top",
      "tracksuit",
      "trainers",
      "trousers"
    ]
  },
  "objects": {
    "addKeyToSuggestions": "",
    "data": [
      "CD",
      "Christmas ornament",
      "acorn",
      "apple",
      "bag",
      "bag of cotton balls",
      "bag of popcorn",
      "bag of rubber bands",
      "ball of yarn",
      "balloon",
      "banana",
      "bananas",
      "bandana",
      "bangle bracelet",
      "bar of soap",
      "baseball",
      "baseball bat",
      "baseball hat",
      "basketball",
      "beaded bracelet",
      "beaded necklace",
      "bed",
      "beef",
      "bell",
      "belt",
      "blouse",
      "blowdryer",
      "bonesaw",
      "book",
      "book of jokes",
      "book of matches",
      "bookmark",
      "boom box",
      "bottle",
      "bottle cap",
      "bottle of glue",
      "bottle of honey",
      "bottle of ink",
      "bottle of lotion",
      "bottle of nail polish",
      "bottle of oil",
      "bottle of paint",
      "bottle of perfume",
      "bottle of pills",
      "bottle of soda",
      "bottle of sunscreen",
      "bottle of syrup",
      "bottle of water",
      "bouquet of flowers",
      "bow",
      "bow tie",
      "bowl",
      "box",
      "box of Q-tips",
      "box of baking soda",
      "box of chalk",
      "box of chocolates",
      "box of crayons",
      "box of markers",
      "box of tissues",
      "bracelet",
      "bread",
      "broccoli",
      "brush",
      "buckle",
      "butter knife",
      "button",
      "camera",
      "can of beans",
      "can of chili",
      "can of peas",
      "can of whipped cream",
      "candle",
      "candlestick",
      "candy bar",
      "candy cane",
      "candy wrapper",
      "canteen",
      "canvas",
      "car",
      "card",
      "carrot",
      "carrots",
      "cars",
      "carton of ice cream",
      "cat",
      "catalogue",
      "cell phone",
      "cellphone",
      "cement stone",
      "chain",
      "chair",
      "chalk",
      "chapter book",
      "check book",
      "chenille stick",
      "chicken",
      "children's book",
      "chocolate",
      "class ring",
      "clay pot",
      "clock",
      "clothes",
      "clothes pin",
      "coffee mug",
      "coffee pot",
      "comb",
      "comic book",
      "computer",
      "conditioner",
      "container of pudding",
      "cookie jar",
      "cookie tin",
      "cork",
      "couch",
      "cow",
      "cowboy hat",
      "craft book",
      "credit card",
      "crow",
      "crowbar",
      "cucumber",
      "cup",
      "dagger",
      "deodorant",
      "desk",
      "dictionary",
      "dog",
      "dolphin",
      "domino set",
      "door",
      "dove",
      "drawer",
      "drill press",
      "egg",
      "egg beater",
      "egg timer",
      "empty bottle",
      "empty jar",
      "empty tin can",
      "eraser",
      "extension cord",
      "eye liner",
      "face wash",
      "fake flowers",
      "feather",
      "feather duster",
      "few batteries",
      "fish",
      "fishing hook",
      "flag",
      "flashlight",
      "floor",
      "flowers",
      "flyswatter",
      "food",
      "football",
      "fork",
      "fridge",
      "frying pan",
      "game CD",
      "game cartridge",
      "garden spade",
      "giraffe",
      "glass",
      "glasses",
      "glow stick",
      "grid paper",
      "grocery list",
      "hair brush",
      "hair clip",
      "hair pin",
      "hair ribbon",
      "hair tie",
      "hammer",
      "hamster",
      "hand bag",
      "hand fan",
      "hand mirror",
      "handbasket",
      "handful of change",
      "handheld game system",
      "hanger",
      "harmonica",
      "helmet",
      "house",
      "ice cream stick",
      "ice cube",
      "ice pick",
      "incense holder",
      "ipod",
      "ipod charger",
      "jar of jam",
      "jar of peanut butter",
      "jar of pickles",
      "jigsaw puzzle",
      "key",
      "key chain",
      "keyboard",
      "keychain",
      "keys",
      "kitchen knife",
      "knife",
      "lace",
      "ladle",
      "lamp",
      "lamp shade",
      "laser pointer",
      "leg warmers",
      "lemon",
      "letter opener",
      "light",
      "light bulb",
      "lighter",
      "lime",
      "lion",
      "lip gloss",
      "locket",
      "lotion",
      "magazine",
      "magnet",
      "magnifying glass",
      "map",
      "marble",
      "martini glass",
      "matchbook",
      "microphone",
      "milk",
      "miniature portrait",
      "mirror",
      "mobile phone",
      "model car",
      "money",
      "monitor",
      "mop",
      "mouse pad",
      "mp3 player",
      "multitool",
      "music CD",
      "nail",
      "nail clippers",
      "nail filer",
      "necktie",
      "needle",
      "notebook",
      "notepad",
      "novel",
      "ocarina",
      "orange",
      "outlet",
      "pack of cards",
      "package of crisp and crunchy edibles",
      "package of glitter",
      "packet of seeds",
      "pail",
      "paint brush",
      "paintbrush",
      "pair of binoculars",
      "pair of dice",
      "pair of earrings",
      "pair of glasses",
      "pair of handcuffs",
      "pair of knitting needles",
      "pair of rubber gloves",
      "pair of safety goggles",
      "pair of scissors",
      "pair of socks",
      "pair of sunglasses",
      "pair of tongs",
      "pair of water goggles",
      "panda",
      "pants",
      "paper",
      "paperclip",
      "pasta strainer",
      "pearl necklace",
      "pen",
      "pencil",
      "pencil holder",
      "pepper shaker",
      "perfume",
      "phone",
      "photo album",
      "picture frame",
      "piece of gum",
      "pillow",
      "pinecone",
      "plastic fork",
      "plate",
      "plush bear",
      "plush cat",
      "plush dinosaur",
      "plush dog",
      "plush frog",
      "plush octopus",
      "plush pony",
      "plush rabbit",
      "plush unicorn",
      "pocketknife",
      "pocketwatch",
      "pool stick",
      "pop can",
      "postage stamp",
      "puddle",
      "purse",
      "purse/bag",
      "quartz crystal",
      "quilt",
      "rabbit",
      "radio",
      "rat",
      "remote",
      "rhino",
      "ring",
      "rock",
      "roll of duct tape",
      "roll of gauze",
      "roll of masking tape",
      "roll of stickers",
      "roll of toilet paper",
      "rolling pin",
      "rope",
      "rubber band",
      "rubber duck",
      "rubber stamp",
      "rug",
      "rusty nail",
      "safety pin",
      "sailboat",
      "salt shaker",
      "sand paper",
      "sandal",
      "sandglass",
      "scallop shell",
      "scarf",
      "scotch tape",
      "screw",
      "screwdriver",
      "seat belt",
      "shampoo",
      "shark",
      "sharpie",
      "shawl",
      "sheep",
      "sheet of paper",
      "shirt",
      "shirt button",
      "shoe lace",
      "shoes",
      "shopping bag",
      "shovel",
      "sidewalk",
      "sketch pad",
      "slipper",
      "small pouch",
      "snail shell",
      "snowglobe",
      "soap",
      "soccer ball",
      "socks",
      "sofa",
      "spatula",
      "speakers",
      "spectacles",
      "spice bottle",
      "sponge",
      "spool of ribbon",
      "spool of string",
      "spool of thread",
      "spool of wire",
      "spoon",
      "spring",
      "squirrel",
      "squirt gun",
      "statuette",
      "steak knife",
      "stick",
      "stick of incense",
      "sticker book",
      "sticky note",
      "stockings",
      "stop sign",
      "straw",
      "street lights",
      "sun glasses",
      "sword",
      "table",
      "tea cup",
      "tea pot",
      "teddies",
      "television",
      "tennis ball",
      "tennis racket",
      "thermometer",
      "thimble",
      "thread",
      "tiger",
      "tire swing",
      "tissue box",
      "toe ring",
      "toilet",
      "toilet paper tube",
      "tomato",
      "tooth pick",
      "toothbrush",
      "toothpaste",
      "toothpick",
      "towel",
      "toy boat",
      "toy car",
      "toy plane",
      "toy robot",
      "toy soldier",
      "toy top",
      "trash bag",
      "tree",
      "trucks",
      "tube of lip balm",
      "tube of lipstick",
      "turtle",
      "tv",
      "tweezers",
      "twister",
      "umbrella",
      "vase",
      "video games",
      "wallet",
      "washcloth",
      "washing machine",
      "watch",
      "water",
      "water bottle",
      "wedding ring",
      "whale",
      "whip",
      "whistle",
      "white out",
      "window",
      "wine glass",
      "wireless control",
      "wishbone",
      "wooden spoon",
      "word search",
      "wrench",
      "wristwatch",
      "zebra",
      "zipper"
    ]
  },
  "appliances": {
    "addKeyToSuggestions": "appliances",
    "data": [
      "air conditioner",
      "air fryer",
      "air ioniser",
      "aroma lamp",
      "attic fan",
      "bachelor griller",
      "back boiler",
      "barbecue",
      "beehive oven",
      "beverage opener",
      "boiler",
      "bread machine",
      "butane torch",
      "can opener",
      "ceiling fan",
      "central vacuum cleaner",
      "clothes dryer",
      "clothes iron",
      "coffee percolator",
      "coffeemaker",
      "combo washer dryer",
      "compactor",
      "convection heater",
      "convection microwave",
      "convection oven",
      "corn roaster",
      "crepe maker",
      "deep fryer",
      "dehumidifier",
      "dishwasher",
      "earth oven",
      "electric cooker",
      "electric water boiler",
      "embroidery machine",
      "energy regulator",
      "espresso machine",
      "fan heater",
      "field kitchen",
      "fire pot",
      "fireplace toaster",
      "flame supervision device",
      "flattop grill",
      "food steamer",
      "garbage disposal unit",
      "hair dryer",
      "hair iron",
      "halogen oven",
      "home server",
      "hot plate",
      "humidifier",
      "HVAC",
      "icebox",
      "instant hot water dispenser",
      "internet refrigerator",
      "kettle",
      "kimchi refrigerator",
      "kitchener range",
      "micathermic heater",
      "microwave oven",
      "mousetrap",
      "oil heater",
      "oven",
      "panini sandwich grill",
      "patio heater",
      "pneumatic vacuum",
      "popcorn maker",
      "pressure cooker",
      "pressure fryer",
      "radiator",
      "reflector oven",
      "refrigerator",
      "rice cooker",
      "rice polisher",
      "robotic vacuum cleaner",
      "rotisserie",
      "sandwich toaster",
      "self-cleaning oven",
      "set-n-forget cooker",
      "sewing machine",
      "slow cooker",
      "solar cooker",
      "sous-vide cooker",
      "soy milk maker",
      "stove",
      "sump pump",
      "susceptor",
      "swamp cooler",
      "tandoor",
      "thermal immersion circulator",
      "thermal mass refrigerator",
      "tie press"
    ]
  },
  "kitchen": {
    "addKeyToSuggestions": "kitchen",
    "data": [
      "Kitchen table",
      "Refrigerator",
      "Dustbin",
      "Kettle",
      "Toaster",
      "Microwave/Gas oven",
      "Blender/ Mixer",
      "Bread bin",
      "Paper towel holder"
    ]
  },
  "cookingUtensils": {
    "addKeyToSuggestions": "cooking utensils|kitchen",
    "data": [
      "Baking tray",
      "Can opener",
      "Board for chopping fruits, vegetables etc",
      "Knives",
      "Jug",
      "Ladle",
      "Bowls",
      "Frying pan",
      "Small pots",
      "Colander",
      "Spatula",
      "Oven gloves",
      "Tea towels",
      "Vegetable or fruit peeler",
      "Wooden spoons",
      "Condiment holders",
      "Placemats",
      "Serving dishes",
      "Bowls",
      "Forks",
      "Knives",
      "Spoons",
      "Drinking glasses",
      "Cups and mugs",
      "Plates"
    ]
  },
  "household": {
    "addKeyToSuggestions": "household",
    "data": [
      "home repairs",
      "home improvements",
      "gardening supplies",
      "linens",
      "home décor",
      "seasonal décor",
      "kitchen gadgets",
      "outdoor home/yard supplies",
      "landscape/gardening services"
    ]
  },
  "housekeeping": {
    "addKeyToSuggestions": "house keeping",
    "data": [
      "Wiping clothes",
      "Scrubbers",
      "Sponges",
      "Washing liquid",
      "Cleaning sprays",
      "General disinfectant",
      "Sweeping brush",
      "Bucket",
      "Mop",
      "Dustpan",
      "Brush",
      "Vacuum cleaner"
    ]
  },
  "bedroom": {
    "addKeyToSuggestions": "bedroom",
    "data": [
      "Bed",
      "Bedside table",
      "Mattress",
      "Bedside lamp",
      "Bed sheets",
      "Duvet ",
      "Duvet cover",
      "Pillows",
      "Pillow covers",
      "Wardrobe",
      "Chest",
      "Dressing table",
      "Hangers"
    ]
  },
  "washroom": {
    "addKeyToSuggestions": "washroom",
    "data": [
      "Shower",
      "Bathtub",
      "Sink",
      "Shower curtain",
      "Shower rings",
      "Towels",
      "hand towels",
      "bath towels",
      "face cloth",
      "Towel rack",
      "Toilet brush",
      "Bathroom mat",
      "Toilet roll and its holder",
      "Plunger",
      "Soap dish",
      "Air freshener",
      "Wastebasket"
    ]
  },
  "living room": {
    "addKeyToSuggestions": "living room",
    "data": [
      "Couch",
      "Coffee table",
      "Curtains ",
      "Curtains rods",
      "Bookshelves",
      "Lamp",
      "TV ",
      "tv stand",
      "CDs, ",
      "DVDs,",
      "bluray,",
      "Wall hanging",
      "Photo frames",
      "Sofa cushions"
    ]
  },
  "first aid": {
    "addKeyToSuggestions": "first aid",
    "data": [
      "Plasters",
      "Bandages",
      "Thermometer",
      "Antibiotic ointments",
      "Antiseptics",
      "Tweezers",
      "Small scissors",
      "Painkillers"
    ]
  },
  "safety": {
    "addKeyToSuggestions": "safety",
    "data": [
      "Torch",
      "Fire extinguisher",
      "Fire blanket",
      "Generator",
      "Smoke alarm",
      "Property alarm",
      "Carbon monoxide alarm"
    ]
  },
  "diy": {
    "addKeyToSuggestions": "diy",
    "data": [
      "Plug extension lead",
      "Step ladder",
      "Hammer",
      "Batteries",
      "Spare light bulbs",
      "Measuring tape",
      "Pliers",
      "Drill",
      "Screws",
      "Screwdrivers"
    ]
  },
  "laundry": {
    "addKeyToSuggestions": "laundry",
    "data": [
      "Washing machine",
      "Iron",
      "Laundry basket",
      "Ironing board cover",
      "Ironing board",
      "Clotheshorse (for indoor drying)"
    ]
  },
  "utilities": {
    "addKeyToSuggestions": "utilities",
    "data": [
      "Hydro",
      "Electric",
      "Power",
      "Natural gas",
      "Alternate heating source",
      "Water, sewer, garbage",
      "Cable",
      "Netflix",
      "HBO max",
      "Hulu",
      "disney plus",
      "Home telephone",
      "Mobile Phone",
      "Internet"
    ]
  },
  "housing": {
    "addKeyToSuggestions": "housing",
    "data": [
      "mortgage",
      "second mortgage",
      "line of credit",
      "mortgage insurance",
      "strata fees",
      "pad rent",
      "property taxes",
      "rent",
      "storage locker",
      "home security",
      "home insurance"
    ]
  },
  "children": {
    "addKeyToSuggestions": "",
    "data": [
      "daycare",
      "babysitting",
      "activities",
      "lessons",
      "sports",
      "clothing",
      "school fees",
      "school supplies",
      "school fundraisers"
    ]
  },
  "pet": {
    "addKeyToSuggestions": "pet",
    "data": ["pet food", "veterinarians/pet medicines", "pet insurance"]
  },
  "education": {
    "addKeyToSuggestions": "tuition",
    "data": [
      "tuition fees",
      "books",
      "supplies (ink/paper/pens/etc)",
      "course materials (calculator)"
    ]
  },
  "entertainment": {
    "addKeyToSuggestions": "entertainment",
    "data": [
      "dining out",
      "lottery tickets",
      "gambling",
      "entertaining at home / bbq",
      "movies",
      "plays",
      "books",
      "music",
      "itunes",
      "hockey",
      "football",
      "pools",
      "concerts",
      "clubs",
      "hobbies"
    ]
  },
  "recreation": {
    "addKeyToSuggestions": "recreation",
    "data": ["sports equipment", "activities", "team dues", "licenses"]
  },
  "donation": {
    "addKeyToSuggestions": "donation",
    "data": ["magazines", "newspapers", "religious organizations", "charity"]
  },
  "miscellaneous": {
    "addKeyToSuggestions": "miscellaneous",
    "data": [
      "professional dues",
      "association fees",
      "gym membership",
      "annual family membership",
      "store membership"
    ]
  }
}
