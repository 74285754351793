import { createFeatureSelector, createSelector, props } from '@ngrx/store';
import {
  iIgnoreTags,
  iIgnoreTagsEntityState,
} from 'src/app/mini-apps/expense-app/common/interface';
import { IgnoreTagsAdaptor } from './ignore-tags.reducer';

// get the selectors
const { selectAll } = IgnoreTagsAdaptor.getSelectors();

export const selectIgnoreTagsFromState =
  createFeatureSelector<iIgnoreTagsEntityState>('ignoreTags');
export const getAllIgnoreTags = createSelector(
  selectIgnoreTagsFromState,
  selectAll,
);

export const getIgnoreTagByTag = createSelector(
  getAllIgnoreTags,
  (ignoredTags: iIgnoreTags[], props: { tag: string }) =>
    ignoredTags.find((tag) => tag.tag === props.tag),
);
