import { createSelector } from '@ngrx/store';
import { IStoreState, IUser } from '../../../../common/app-interfaces';

// get the selectors
export const selectUserInfo = (state: IStoreState) => state.user;

export const GetUserInfo = createSelector(
  selectUserInfo,
  (state: IUser) => state,
);

export const GetUserTax = () =>
  createSelector(selectUserInfo, (user) => +user.extraInfo.tax);

export const GetUserName = () =>
  createSelector(selectUserInfo, (user) => user.extraInfo.firstName);

export const GetUserAvatar = () =>
  createSelector(selectUserInfo, (user) => user.extraInfo.avatar);

export const GetUserDefaultExpenseTrackerGroupId = () =>
  createSelector(
    selectUserInfo,
    (user) => user.extraInfo.settings.expenseTracker.defaultUserGroupId,
  );
